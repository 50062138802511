export const CATEGORY = {
    'HAIRDRESSER': 'Hairdresser',
    'GARAGE': 'Garage',
    'REAL_ESTATE': 'Real estate',
    'GARDENER': 'Gardener',
    'PLUMBER': 'Plumber',
    'TATTOO_ARTIST': 'Tattoo artist',
    'VETERINARY': 'Veterinary',
    'BABYSITTERS': 'Babysitter',
    'PRESSING': 'Pressing',
    'GROOMER': 'Groomer'
}

export const SALE_MODE = {
    'TAKE_AWAY': 'TO TAKE AWAY',
    'DELIVERY': 'DELIVERY',
    'AWAY_DELIVERY': 'TO TAKE AWAY + DELIVERY'
}

export const MINIMUM_CREDIT_CARD = {
    0: 0,
    5: 5,
    10: 10,
    15: 15
}

export const googleApiKey = 'AIzaSyC6OiTcIW-6eztuNw7zvy1cPlNfhn3BKE0';