import CryptoJS from 'crypto-js'
import {STORAGE_KEY} from "../constant/constant";
import config from "./config";

export const setStorage = (key: string, item: any) => {
    if (typeof item === "object" && item !== null) {
        sessionStorage.setItem(key, encryptedData(JSON.stringify(item)))
    } else {
        sessionStorage.setItem(key, encryptedData(item))
    }
}

export const getItemFromStorage = (key: string) => {
    let item = sessionStorage.getItem(key)
    if (item) return decryptedData(item)
}

export const setToken = (token: string) => {
    sessionStorage.setItem(STORAGE_KEY.TOKEN, encryptedData(token))
}

export const getToken = () => {
    const token = sessionStorage.getItem(STORAGE_KEY.TOKEN)
    if (token) return decryptedData(token)
}

export const clearStorage = () => {
    Object.keys(STORAGE_KEY).forEach(key => {
        // @ts-ignore
        let storageKey = STORAGE_KEY[key]
        sessionStorage.removeItem(storageKey)
    })
}

const encryptedData = (item: any) => {
    return CryptoJS.AES.encrypt(item, config.STORAGE_SECRET_KEY).toString();
}

const decryptedData = (encryptedData: string) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, config.STORAGE_SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
}
