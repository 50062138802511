export type Error = {
  statusCode: number
  message: string
}

export type Phone = {
  phoneNumber: string
  countryCode: number
}

export type updateContactType = {
  manager: PersonalContact
  society: SocialContact
}

export type PersonalContact = {
  email: string
  country: string
  city: string
  postalCode: string
  street: string
  phone: Phone
  firstName: string
  lastName: string
  fullName: string
  gender: string
}

export type SocialContact = {
  email: string
  country: string
  city: string
  postalCode: string
  street: string
  phone: Phone
  name: string
  SIRETnumber: string
  address: string
  password: string
  rePassword: string
  legalStatus: LEGAL_STATUS
  tradeName: string
}

export type ShopContact = {
  email: string
  country: string
  city: string
  postalCode: string
  street: string
  phone: Phone
  name: string
}

export enum LEGAL_STATUS {
  ARTIST = "artisan",
  FREELANCE = "liberal-profession",
  AUTOMOTIVE_BUSINESSMAN = "auto-entrepreneur",
  COMMERCIAL_SOCIETY = "commercial-society",
}

export interface UserData {
  accessToken: string
  user: User
}

export interface User {
  id: string
  manager: PersonalContact
  society: SocialContact
  shopContact: ShopContact
  isReceiveNotification: boolean
  signUpTracking: {
    isConfig: boolean
    isMollie: boolean
    isValidatePhone: boolean
    isValidateEmail: boolean
    isSubscription: boolean
  }
  isValidatePhone: boolean
  isSubscription: boolean
  promotionCode: string
  importedPlaceId: string
  isValidateEmail: boolean
  signUpError: Error
}

export type KycDocumentsFile = {
  id: string
  UserId: string
  Tag: string
  CreationDate: number
  ProcessedDate: string
  Status: string
  RefusedReasonType: string
  RefusedReasonMessage: string
  images: ImageKyc[]
  documents: DocumentKyc[]
}
export interface DocumentKyc {
  id: string
  fileId: string
  pathDisplay: string
  pathLower: string
  size: number
  createdAt: string
  updatedAt: string
  originalName: string
}
export interface ImageKyc {
  id: string
  url: string
  resizedUrl: string
  originalName: string
}

export type FileChoosen = {
  url: string
  type: TYPE_FILE
}

export enum TYPE_FILE {
  IMAGE = "image",
  PDF = "pdf",
  NONE = " none",
}
