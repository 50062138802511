import React, { useEffect, useMemo, useRef, useState, memo } from "react";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import { FixedSizeList } from "react-window";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { MerchantSelectionHeader } from "pages/notification-page/components/MerchantSelectionHeader";
import { MerchantSelectionFooter } from "pages/notification-page/components/MerchantSelectionFooter";
import { Checkbox, ListItemIcon } from "@material-ui/core";
import { IZone } from "interfaces/apis/category.inteface";

interface MerchantSelectionProps {
  selectedMerchant: IZone[],
  isOpen: boolean,
  onToggle: (value: boolean) => void,
  listOption: IZone[],
  onSubmitSelection: (value: IZone[]) => void
}

export const MerchantSelection = (props: MerchantSelectionProps) => {
  const { isOpen, onToggle, listOption, onSubmitSelection, selectedMerchant } = props;
  const sheetRef = useRef<BottomSheetRef | null>(null);
  const [checkedItems, setCheckedItems] = useState<IZone[]>([]);

  const handleCheckChange = (newCheckedItems: IZone) => {
    const findItem = [...checkedItems].find((it) => it.id === newCheckedItems.id);
    if (!!findItem) {
      const filterList = [...checkedItems].filter(it => it.id !== newCheckedItems.id);
      setCheckedItems(filterList);
    } else {
      const newList = [...checkedItems, newCheckedItems];
      setCheckedItems(newList);
    }
  };
  const handleConfirmMerchant = () => {
    onSubmitSelection(checkedItems);
    onToggle(false);
  };

  useEffect(() => {
    if (selectedMerchant) {
      setCheckedItems(selectedMerchant);
    }
  }, [selectedMerchant])

  return (
    <BottomSheet
      open={isOpen}
      ref={sheetRef}
      onDismiss={() => {
        onToggle(false);
        onSubmitSelection(checkedItems);
      }}
      header={<MerchantSelectionHeader onToggle={onToggle} />}
      footer={
        <MerchantSelectionFooter onSelectMerchant={handleConfirmMerchant} />
      }
    >
      <FixedSizeList
        itemData={{ listOption, handleCheckChange, checkedItems }}
        height={400}
        width={"100%"}
        itemSize={46}
        itemCount={listOption.length}
      >
        {RenderRowMemo}
      </FixedSizeList>
    </BottomSheet>
  )
}

const RenderRow = (props: any) => {
  const { index, style, data } = props;
  const { listOption, handleCheckChange, checkedItems } = data;
  const [checked, setChecked] = useState<IZone | null>(null);

  const handleToggle = (value: IZone) => () => {
    if (value?.id === checked?.id) {
      setChecked(null);
    } else {
      setChecked(value);
    }
    handleCheckChange(value);
  };

  useEffect(() => {
    if (checkedItems) {
      const findItem = checkedItems.find((it: IZone) => it?.id === (checked?.id || listOption[index]?.id));
      if (!!findItem) {
        setChecked(findItem);
      }
    }
  }, [checkedItems, checked])

  const item = listOption[index];

  return (
    <ListItem button style={style} key={index} onClick={handleToggle(item)}>
      <ListItemText primary={item.name} />
      <ListItemIcon>
        <Checkbox
          edge="end"
          checked={checked?.id === item.id}
          tabIndex={-1}
          disableRipple
        />
      </ListItemIcon>
    </ListItem>
  );
}

const RenderRowMemo = memo(RenderRow)