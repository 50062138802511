import "./card.css"
import { ICardInterface } from "../../interfaces/card.interface"
import { Trans } from "react-i18next"
import images from "../../theme/Images"

const Card = (props: ICardInterface) => {
  const renderIcon = (isShowIcon: boolean) => {
    return isShowIcon ? <img src={images.check} alt="icon" /> : <></>
  }

  const renderFooter = (isDisplayFooter = true, description?: string) => {
    return isDisplayFooter
        ?
      <div className="card-footer text-center mt-20">
        <span className="footer-content text-gray text-small">
          {description}
        </span>
      </div>
        :
      <></>

  }

  return (
    <div onClick={props.onClick} className="card mt-20 pb-20 mb-20">
      <div className="card-header mb-10">
        {props.header.content}
        {renderIcon(props.header.isShowCheckLogo)}
      </div>
      <div className="card-body">
        <div className="card-content text-center pb-20">
          {props.body.content}
        </div>
      </div>
      {renderFooter(props.footer?.isDisplay, props.footer?.description)}
    </div>
  )
}

export default Card
