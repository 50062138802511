import {createContext, useContext} from "react";

export type TypeOfferContextType = {
  typeOffer: string,
  setTypeOffer: (type: string) => void
}

export const TypeOfferContext = createContext<TypeOfferContextType>({
  typeOffer: '',
  setTypeOffer: (type: string) => console.warn('No config provider')
})

export const useTypeOffer = () => useContext(TypeOfferContext)
