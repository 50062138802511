import {baseApi} from '../base/base.api'
import {AxiosResponse} from "axios";
import { IOfferSubscription } from "../../interfaces/offer-subscription.interface";

export interface IPromoCode {
    id: string,
    title: string,
    detail: string,
    detailInCaseOf: string,
    code: string,
    type?: string,
    price: {
      value: number,
      currency: string
    }
}

const PROMOTION_URL = {
    VALIDATE: '/v2/offer-subscriptions/check-offer-subscription'
}

const PROMOTION_CODE = 'v1/offer-subscription-category';

export const validatePromoCode = (promotionCode: string, googlePlaceId: string, offerSubscriptionCategoryId: string): Promise<AxiosResponse<IOfferSubscription>> => {
    return baseApi.post<IOfferSubscription>(PROMOTION_URL.VALIDATE, {promotionCode, googlePlaceId, offerSubscriptionCategoryId})
}


export const getPromoCodes = (): Promise<AxiosResponse<IPromoCode>> => {
    return baseApi.get<IPromoCode>(PROMOTION_CODE)
}
