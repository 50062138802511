import React, {Dispatch, SetStateAction} from 'react'
import {IRegister} from "../interfaces/register-page.interface";

export const initial: IRegister = {
    companyName: '',
    tradeName: '',
    email: '',
    password: '',
    confirmPassword: '',
    countryNumberCode: '33',
    phoneNumber: '',
    channel: '',
    lastName: '',
    firstName: '',
    countryNumberCode2: '33',
    phoneNumber2: '',
    isMale: true,
    cardHolderName: '',
    iban: '',
    bic: '',
    type: '',
    country: '',
    street: '',
    address: '',
    location: {
        lat: 0,
        lng: 0
    },
    googlePlaceId: '',
    countryCode: 0,
    legalStatus: '',
    saveFromStep1: false,
}

export const RegisterInfoContext: React.Context<{ registerInfo: IRegister, setRegisterInfo?: Dispatch<SetStateAction<IRegister>> }> = React.createContext({
    registerInfo: initial,
})
