import {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { Field, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import { useHistory } from "react-router-dom"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { MerchantAction } from "../../redux/actions"
import { RootState } from "../../redux/reducers/RootReducer"

// Functions
import {
  getBase64,
  getCountryCode2,
  urltoFile,
  usePrevious,
} from "../../common/helper"
import _ from "lodash"
import { getItemFromStorage, setStorage } from "../../common/storage"

// Components
import { Card, CardContent, IconButton, Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { ArrowDropDown, PhotoCamera } from "@material-ui/icons"
import Loading from "../../components/loading/Loading"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import { RegisterModel } from "../../model/register.model"

// Constants
import {
  ERROR,
  LEGAL_STATUS,
  REGEX,
  STORAGE_KEY,
  TIME_OUT,
  TYPE_ACCOUNT
} from "../../constant/constant"

// Styles
import "./register-page.css"

// Language
import { useTranslation } from "react-i18next"

// Types
import { FileChoosen, Phone, TYPE_FILE } from "../../types"
import { RegisterInfoContext } from "../../context/register-context"
import { IRegister } from "../../interfaces/register-page.interface"
import { isValidPhoneNumber } from 'libphonenumber-js';
import Autocomplete from "react-google-autocomplete";
import { googleApiKey } from "constant/config.constant";
import { TypeAccountContext } from '../../context/type-account.context';

type DataUpdatePassword = {
  newPassword: string
  oldPassword: string
}

const RegisterSchema = Yup.object().shape({
  companyName: Yup.string()
    .matches(REGEX.SHOP_NAME, "tradeNameRequired")
    .min(3, "tradeNameRequired")
    .max(50, "tradeNameRequired")
    .required("Required"),
  tradeName: Yup.string()
    .matches(REGEX.SHOP_NAME, "tradeNameRequired")
    .min(3, "tradeNameRequired")
    .max(50, "tradeNameRequired")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(6, "passwordLength")
    .max(20, "passwordLength")
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "matchPassword"),
  countryNumberCode: Yup.string()
      .matches(REGEX.COUNTRY_NUMBER_CODE, "invalidCountryNumberCode")
      .min(1, "invalidCountryNumberCode")
      .max(4, "invalidCountryNumberCode")
      .required("Required"),
  phoneNumber: Yup.string()
    .matches(REGEX.PHONE_NUMBER, "invalidPhoneNumber")
    .required("Required"),
  channel: Yup.string().required("Required"),
  lastName: Yup.string()
    .matches(REGEX.NAME, "lastNameRequired")
    .min(3, "lastNameRequired")
    .max(50, "lastNameRequired")
    .required("Required"),
  firstName: Yup.string()
    .matches(REGEX.NAME, "lastNameRequired")
    .min(3, "firstNameRequired")
    .max(50, "firstNameRequired")
    .required("Required"),
  countryNumberCode2: Yup.string()
      .matches(REGEX.COUNTRY_NUMBER_CODE, "invalidCountryNumberCode")
      .min(1, "invalidCountryNumberCode")
      .max(4, "invalidCountryNumberCode")
      .required("Required"),
  phoneNumber2: Yup.string()
    .matches(REGEX.PHONE_NUMBER, "invalidPhoneNumber")
    .required("Required"),
  iban: Yup.string().matches(REGEX.IBAN, "Invalid IBAN"),
  cardHolderName: Yup.string(),
  bic: Yup.string().matches(REGEX.BIC, "Invalid BIC"),
  legalStatus: Yup.string().required("Required"),
})

const Error = (props: { message: string }) => {
  const { t } = useTranslation()
  return <div className="text-red mt-10">{t(`${props.message}`)}</div>
}

const RegisterPage = () => {
  // HOOKS
  const { t } = useTranslation()
  const { registerInfo, setRegisterInfo } = useContext(RegisterInfoContext)
  const { typeAccount, setTypeAccount } = useContext(TypeAccountContext)
  const history = useHistory()
  const dispatch = useDispatch()

  const MerchantReducer = useSelector(
    (state: RootState) => state.MerchantReducer
  )

  const {
    isExistEmail,
    fetchingCheckEmailExisted,
    fetchingSignUp,
    errorCheckEmailExisted,
    errorSignUp,
    fetchingCheckUserMolliePromoted,
    errorCheckUserMolliePromoted,
    userData,
    fetchingUploadIbanKyc,
    errorUploadIbanKyc,
    kycDocumentShow,
    errorUpdateMerchantContact,
    fetchingUpdateMerchantContact,
    fetchingUpdatePassword,
    errorUpdatePassword,
    fetchingCreateMollieBank,
    errorCreateMollieBank,
    mollie,
    fetchingGetMe,
    errorGetMe,
    kycDocument,
  } = MerchantReducer

  const user = userData?.user

  const preFetchingCheckEmailExisted = usePrevious(fetchingCheckEmailExisted)
  const preFetchingSignUp = usePrevious(fetchingSignUp)
  const preFetchingUploadIbanKyc = usePrevious(fetchingUploadIbanKyc)
  const preFetchingCreateMollieBank = usePrevious(fetchingCreateMollieBank)
  const preFetchingGetMe = usePrevious(fetchingGetMe)
  const preFetchingUpdatePassword = usePrevious(fetchingUpdatePassword)
  const preFetchingUpdateMerchantContact = usePrevious(
    fetchingUpdateMerchantContact
  )
  const preFetchingCheckUserMolliePromoted = usePrevious(
    fetchingCheckUserMolliePromoted
  )

  const inputRef = useRef(null);

  const formik = useFormik({
    initialValues: registerInfo,
    onSubmit: values => {
      onSubmit(values);
    },
    validationSchema: RegisterSchema,
    enableReinitialize: true
  });

  // STATE
  const [placeInfo, setPlace] = useState<any>({})
  const [isMale, setGender] = useState<boolean>(registerInfo.isMale)
  const [lat, setLatitude] = useState<number>(0)
  const [lng, setLongitude] = useState<number>(0)
  const [openAlert, setOpenAlert] = useState({ isOpen: false, message: "" })
  const [isShowPassword, setShowPassword] = useState(false)
  const [isShowConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false)
  const [isChangeBankInfo, setIsChangeBankInfo] = useState<boolean>(false)
  const [dataUpdatePassword, setDataUpdatePassword] =
    useState<DataUpdatePassword>({
      newPassword: "",
      oldPassword: registerInfo.password,
    })
  // img show local
  const imgDefault = !kycDocumentShow
    ? { url: "", type: TYPE_FILE.NONE }
    : !kycDocumentShow?.images.length
    ? { url: kycDocumentShow?.documents[0].originalName, type: TYPE_FILE.PDF }
    : { url: kycDocumentShow?.images[0].url, type: TYPE_FILE.IMAGE }
  const [img, setImg] = useState<FileChoosen>(imgDefault)

  const kycDocumentDefault = urltoFile(
    kycDocument,
    kycDocumentShow?.images[0]?.originalName ||
      kycDocumentShow?.documents[0]?.originalName
  )

  // img to send server
  const [kycImg, setKycImg] = useState<any>(kycDocumentDefault)

  const onSubmit = async (values: IRegister) => {
    const formValues = {
      ..._.cloneDeep(values),
      channel: values.channel ? values.channel : placeInfo.channel,
      countryCode: values.countryCode ? values.countryCode : placeInfo.countryCode,
      countryNumberCode: values.countryNumberCode ? values.countryNumberCode : placeInfo.countryNumberCode,
      countryNumberCode2: values.countryNumberCode2 ? values.countryNumberCode2 : placeInfo.countryNumberCode2,
      street: values.street ? values.street : placeInfo.street,
      country: values.country ? values.country : placeInfo.country,
      googlePlaceId: values.googlePlaceId ? values.googlePlaceId : placeInfo.googlePlaceId,
    }
    const value: IRegister = {
      ..._.cloneDeep(formValues),
      isMale,
      location: {
        lat,
        lng,
      },
      // @ts-ignore
      type: typeAccount
    }
    // SAVE INFO TO LOCAL & UPDATE FORM FIELD
    //@ts-ignore
    setRegisterInfo(value)
    setStorage(STORAGE_KEY.REGISTER, value)

    if (value.countryNumberCode && value.phoneNumber && value.countryNumberCode2 && value.phoneNumber2) {
      if (!isValidPhoneNumber('+' + value.countryNumberCode + value.phoneNumber)) {
        return setOpenAlert({ isOpen: true, message: ERROR.INVALID_PHONE_NUMBER })
      }
      if(!isValidPhoneNumber('+' + value.countryNumberCode2 + value.phoneNumber2)) {
        return setOpenAlert({ isOpen: true, message: ERROR.INVALID_PHONE_NUMBER })
      }
    }

    if (!value.googlePlaceId)
      return setOpenAlert({ isOpen: true, message: ERROR.INVALID_HERE_ID })

    if (!img) return setOpenAlert({ isOpen: true, message: "fillField" })

    if (user?.manager?.email === value.email) {
      const updateUserData = new RegisterModel(value)
      setDataUpdatePassword({
        ...dataUpdatePassword,
        newPassword: value.password,
      })
      dispatch(
        MerchantAction.updateMerchantContactRequest(updateUserData.merchantInfo)
      )
    } else {
      setDataUpdatePassword({
        ...dataUpdatePassword,
        oldPassword: value.password,
      })
      dispatch(MerchantAction.checkEmailExistedRequest(value.email))
    }
  }

  const handleSuggestClick = (selectedValue: any) => {
    const address = selectedValue.address_components;
    let newRegisterInfo: any = {}
    newRegisterInfo = {
      channel: selectedValue?.formatted_address,
      countryCode: getCountryCode2(address?.find((it: any) => it?.types?.includes('country'))?.short_name ?? ''),
      countryNumberCode: getCountryCode2(address?.find((it: any) => it?.types?.includes('country'))?.short_name ?? ''),
      countryNumberCode2: getCountryCode2(address?.find((it: any) => it?.types?.includes('country'))?.short_name ?? ''),
      street: address?.find((it: any) => it?.types?.includes('route'))?.long_name,
      country: address?.find((it: any) => it?.types?.includes('country'))?.long_name,
      googlePlaceId: selectedValue?.place_id,
    }
    formik.setFieldValue('countryNumberCode', newRegisterInfo?.countryNumberCode);
    formik.setFieldValue('countryNumberCode2', newRegisterInfo?.countryNumberCode);
    //@ts-ignore
    setPlace(newRegisterInfo)
  }

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChangeBankInfo(true)
    if (event.target.files && event.target.files[0]) {
      let imgFile = event.target.files[0]
      if (imgFile.size > 15000000) {
        setOpenAlert({ isOpen: true, message: ERROR.FILE_TO_BIG })
        setImg({ url: "", type: TYPE_FILE.NONE })
      } else {
        setKycImg(imgFile)
        if (imgFile.type === "application/pdf") {
          setImg({ url: imgFile.name, type: TYPE_FILE.PDF })
        } else {
          setImg({ url: URL.createObjectURL(imgFile), type: TYPE_FILE.IMAGE })
        }
        getBase64(imgFile).then((data: any) => {
          dispatch(MerchantAction.saveKycDocument(data))
          setStorage(STORAGE_KEY.KYC_DOCUMENT, data)
        })
      }
    }
  }

  const convertData = (file: any) => {
    const formData = new FormData()
    formData.append("file", file)
    return formData
  }

  const renderLegalOption = () => {
    return Object.keys(LEGAL_STATUS).map((key) => {
      // @ts-ignore
      const value = LEGAL_STATUS[key]
      return (
        <option key={key} value={value}>
          {t(`${key}`)}
        </option>
      )
    })
  }

  const renderShowPassword = (
    isShowPass: boolean,
    setShowPass: (showPass: boolean) => void
  ) => {
    return isShowPass ? (
      <VisibilityOffIcon
        className="input-icon"
        onClick={() => setShowPass(!isShowPass)}
      />
    ) : (
      <VisibilityIcon
        className="input-icon"
        onClick={() => setShowPass(!isShowPass)}
      />
    )
  }

  useEffect(() => {
    if (user?.manager?.email === registerInfo.email) {
      if (dataUpdatePassword.newPassword === dataUpdatePassword.oldPassword) {
        setIsChangePassword(false)
      } else {
        setIsChangePassword(true)
      }
    }
  }, [dataUpdatePassword])

  useEffect(() => {
    if (!fetchingCheckEmailExisted && preFetchingCheckEmailExisted) {
      if (errorCheckEmailExisted) {
        setOpenAlert({
          isOpen: true,
          message: errorCheckEmailExisted.message,
        })
      }
      if (isExistEmail) {
        setOpenAlert({
          isOpen: true,
          message: ERROR.EMAIL_EXISTED,
        })
      } else {
        const phones: Phone[] = [
          {
            countryCode: registerInfo.countryCode,
            phoneNumber: registerInfo.phoneNumber,
          },
          {
            countryCode: registerInfo.countryCode,
            phoneNumber: registerInfo.phoneNumber2,
          },
        ]
        dispatch(MerchantAction.checkUserMolliePromotedRequest(phones))
      }
    }
  }, [fetchingCheckEmailExisted, errorCheckEmailExisted])

  useEffect(() => {
    if (
      preFetchingCheckUserMolliePromoted &&
      !fetchingCheckUserMolliePromoted
    ) {
      if (errorCheckUserMolliePromoted) {
        setOpenAlert({
          isOpen: true,
          message: errorCheckUserMolliePromoted.message,
        })
      } else {
        if (registerInfo.bic && registerInfo.iban && registerInfo.cardHolderName) {
          dispatch(MerchantAction.uploadIbanKycRequest(convertData(kycImg)))
        } else {
          const registerBody = new RegisterModel(registerInfo)
          dispatch(MerchantAction.signUpRequest(registerBody.register))
        }
      }
    }
  }, [fetchingCheckUserMolliePromoted, errorCheckUserMolliePromoted])

  useEffect(() => {
    if (preFetchingUploadIbanKyc && !fetchingUploadIbanKyc) {
      if (errorUploadIbanKyc) {
        setOpenAlert({
          isOpen: true,
          message: errorUploadIbanKyc.message,
        })
        return
      }
      const registerBody = new RegisterModel(registerInfo, kycDocumentShow.id)
      if (user?.manager?.email === registerInfo.email) {
        if (errorUpdateMerchantContact?.statusCode === 110) {
          dispatch(MerchantAction.signUpRequest(registerBody.register))
        } else {
          dispatch(
            MerchantAction.createMollieBankRequest(registerBody.bankInfo)
          )
        }
      } else {
        dispatch(MerchantAction.signUpRequest(registerBody.register))
      }
    }
  }, [fetchingUploadIbanKyc, errorUploadIbanKyc])

  useEffect(() => {
    if (preFetchingSignUp && !fetchingSignUp) {
      if (errorSignUp) {
        setOpenAlert({
          isOpen: true,
          message: errorSignUp.message,
        })
        return
      }
      if (user?.signUpError?.message) {
        setIsChangeBankInfo(false)
        setOpenAlert({
          isOpen: true,
          message: "invalidBank",
        })
      } else {
        setIsChangeBankInfo(false)
        history.push("/confirm-email")
      }
    }
  }, [fetchingSignUp, errorSignUp])

  useEffect(() => {
    if (preFetchingUpdateMerchantContact && !fetchingUpdateMerchantContact) {
      if (errorUpdateMerchantContact) {
        if (errorUpdateMerchantContact?.statusCode === 110) {
          dispatch(MerchantAction.checkEmailExistedRequest(registerInfo.email))
        } else {
          setOpenAlert({
            isOpen: true,
            message: errorUpdateMerchantContact.message,
          })
        }
        return
      }
      if (isChangePassword) {
        dispatch(
          MerchantAction.updatePasswordRequest({
            ...dataUpdatePassword,
            isForceLogOutAllDevices: false,
          })
        )
      } else if (user?.signUpError?.message || isChangeBankInfo) {
        dispatch(MerchantAction.uploadIbanKycRequest(convertData(kycImg)))
      } else {
        setIsChangeBankInfo(false)
        history.push("/confirm-email")
      }
    }
  }, [fetchingUpdateMerchantContact, errorUpdateMerchantContact])

  useEffect(() => {
    if (preFetchingUpdatePassword && !fetchingUpdatePassword) {
      setDataUpdatePassword({
        ...dataUpdatePassword,
        oldPassword: registerInfo.password,
      })
      if (errorUpdatePassword) {
        setOpenAlert({
          isOpen: true,
          message: errorUpdatePassword.message,
        })
        return
      }
      if (user?.signUpError?.message || isChangeBankInfo) {
        dispatch(MerchantAction.uploadIbanKycRequest(convertData(kycImg)))
      } else {
        setIsChangeBankInfo(false)
        history.push("/confirm-email")
      }
    }
  }, [fetchingUpdatePassword, errorUpdatePassword])

  useEffect(() => {
    if (preFetchingCreateMollieBank && !fetchingCreateMollieBank) {
      if (errorCreateMollieBank) {
        setOpenAlert({
          isOpen: true,
          message: errorCreateMollieBank.message,
        })
        return
      }
      if (!Object.keys(mollie).length) {
        setOpenAlert({
          isOpen: true,
          message: "invalidBank",
        })
      } else {
        setIsChangeBankInfo(false)
        dispatch(MerchantAction.getMeRequest())
      }
    }
  }, [fetchingCreateMollieBank, errorCreateMollieBank])

  useEffect(() => {
    if (preFetchingGetMe && !fetchingGetMe) {
      if (errorGetMe) {
        setOpenAlert({
          isOpen: true,
          message: errorGetMe.message,
        })
      } else {
        setIsChangeBankInfo(false)
        history.push("/confirm-email")
      }
    }
  }, [fetchingGetMe, errorGetMe])

  useEffect(() => {
    const watcher = navigator.geolocation.watchPosition((position) => {
      const { latitude, longitude } = position.coords
      setLatitude(latitude)
      setLongitude(longitude)
    })

    return () => {
      navigator.geolocation.clearWatch(watcher)
    }
  }, [lat, lng])
  return (
    <div className="register-page pt-20">
      <div className="register-page-header">
        <h2 className="title text-large text-left">{t("society")}</h2>
      </div>
      <div className="register-page-body pt-10">
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className="form-group">
          <div className="mb-10">
            <Field
              className={`register-form_field text-large ${
                formik.errors.companyName && formik.touched.companyName
                  ? "register-form_field-error"
                  : ""
              }`}
              type="text"
              name="companyName"
              placeholder={t("companyName")}
              required
              autoFocus
            />
            {formik.errors.companyName && formik.touched.companyName ? (
              <Error message={formik.errors.companyName} />
            ) : null}
          </div>
          <div className="mb-10">
            <Field
              className={`register-form_field text-large ${
                formik.errors.tradeName && formik.touched.tradeName
                  ? "register-form_field-error"
                  : ""
              }`}
              type="text"
              placeholder={t("shopName")}
              name="tradeName"
              required
            />
            {formik.errors.tradeName && formik.touched.tradeName ? (
              <Error message={formik.errors.tradeName} />
            ) : null}
          </div>
          <div className="mb-10">
            <ArrowDropDown className="input-icon" />
            <Field
              placeholder={"legal status"}
              className="register-form_field text-large"
              as="select"
              name="legalStatus"
            >
              <option value="" disabled hidden>
                {t("legalStatus")}
              </option>
              {renderLegalOption()}
            </Field>
            {formik.errors.legalStatus && formik.touched.legalStatus ? (
              <Error message={formik.errors.legalStatus} />
            ) : null}
          </div>
          <div className="mb-10">
            <Field
              className={`register-form_field text-large ${
                formik.errors.email && formik.touched.email
                  ? "register-form_field-error"
                  : ""
              }`}
              type="email"
              placeholder={t("email")}
              name="email"
              required
            />
            {formik.errors.email && formik.touched.email ? (
              <Error message={formik.errors.email} />
            ) : null}
          </div>
          <div className="mb-10">
            {renderShowPassword(isShowPassword, setShowPassword)}
            <Field
              className={`register-form_field text-large ${
                formik.errors.password && formik.touched.password
                  ? "register-form_field-error"
                  : ""
              }`}
              type={isShowPassword ? "text" : "password"}
              placeholder={t("createPassword")}
              name="password"
              required
            />
            {formik.errors.password && formik.touched.password ? (
              <Error message={formik.errors.password} />
            ) : null}
          </div>
          <div className="mb-20">
            {renderShowPassword(
              isShowConfirmPassword,
              setShowConfirmPassword
            )}
            <Field
              className={`register-form_field text-large ${
                formik.errors.confirmPassword && formik.touched.confirmPassword
                  ? "register-form_field-error"
                  : ""
              }`}
              type={isShowConfirmPassword ? "text" : "password"}
              placeholder={t("confirmPassword")}
              name="confirmPassword"
              required
            />
            {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
              <Error message={formik.errors.confirmPassword} />
            ) : null}
          </div>
          <div className="mb-10">
            <Field
              name="channel"  
              render={({ field, form: { errors, touched }}: any) => (
                <>
                  <Autocomplete
                    {...field}
                    disabled={formik.values.channel.length && formik.values.saveFromStep1}
                    className={`register-form_field text-large ${
                      errors[field.name] && touched.channel
                          ? "register-form_field-error"
                          : ""
                    }`}
                    ref={inputRef}
                    placeholder={t("channel")}
                    apiKey={googleApiKey}
                    onPlaceSelected={(selected: any) => {
                      formik.handleChange(selected?.formatted_address)
                      formik.setFieldValue("channel", selected?.formatted_address)
                      handleSuggestClick(selected)
                    }}
                    options={{
                      types: ["geocode", "establishment"],
                      componentRestrictions: "us",
                    }}
                  />
                  {touched[field.name] && errors[field.name] && <Error message={errors[field.name]} />}
                </>
              )}
            />
          </div>
          <div className="mb-10 flex-between-start gap-20">
            <div className='w-30'>
              <Field
                  className={`register-form_field register-form_field_before text-large ${
                      formik.errors.countryNumberCode && formik.touched.countryNumberCode
                          ? "register-form_field-error"
                          : ""
                  }`}
                  type="text"
                  inputMode="numeric"
                  placeholder={t("countryNumberCode")}
                  name="countryNumberCode"
                  required
              />
              {formik.errors.countryNumberCode && formik.touched.countryNumberCode ? (
                  <Error message={formik.errors.countryNumberCode} />
              ) : null}
            </div>
            <div className="w-70">
              <Field
                  className={`register-form_field text-large ${
                      formik.errors.phoneNumber && formik.touched.phoneNumber
                          ? "register-form_field-error"
                          : ""
                  }`}
                  type="text"
                  inputMode="numeric"
                  placeholder={t("phoneNumberStore")}
                  name="phoneNumber"
                  required
                  onChange={(e: any) => {
                    formik.handleChange(e)
                    formik.setFieldValue("phoneNumber2", e.target.value)
                  }}
              />
              {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
                  <Error message={"Invalid phone number"} />
              ) : null}
            </div>
          </div>
          <div className="register-page-header ml-auto mr-auto">
            <h2 className="title text-large text-left">
              {t("REPRESENTED BY")}
            </h2>
          </div>
          <div className="pt-20 flex-center-center">
            <div
              onClick={() => setGender(true)}
              className={`btn-container btn-radio ${
                isMale ? "btn-radio-choose" : ""
              }`}
            >
              Mr
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div
              onClick={() => setGender(false)}
              className={`btn-container btn-radio ${
                !isMale ? "btn-radio-choose" : ""
              }`}
            >
              Mme
            </div>
          </div>
          <div className="pt-10">
            <div className="mb-10">
              <Field
                className={`register-form_field text-large ${
                  formik.errors.lastName && formik.touched.lastName
                    ? "register-form_field-error"
                    : ""
                }`}
                type="text"
                placeholder={t("lastName")}
                name="lastName"
                required
              />
              {formik.errors.lastName && formik.touched.lastName ? (
                <Error message={formik.errors.lastName} />
              ) : null}
            </div>
            <div className="mb-10">
              <Field
                className={`register-form_field text-large ${
                  formik.errors.firstName && formik.touched.firstName
                    ? "register-form_field-error"
                    : ""
                }`}
                type="text"
                placeholder={t("firstName")}
                name="firstName"
                required
              />
              {formik.errors.firstName && formik.touched.firstName ? (
                <Error message={formik.errors.firstName} />
              ) : null}
            </div>
            <div className="mb-10 flex-between-start gap-20">
              <div className='w-30'>
                <Field
                  className={`register-form_field register-form_field_before text-large ${
                    formik.errors.countryNumberCode2 && formik.touched.countryNumberCode2
                        ? "register-form_field-error"
                        : ""
                }`}
                  type="text"
                  inputMode="numeric"
                  placeholder={t("countryNumberCode")}
                  name="countryNumberCode2"
                  required
                />
                {formik.errors.countryNumberCode2 && formik.touched.countryNumberCode2 ? (
                    <Error message={formik.errors.countryNumberCode2} />
                ) : null}
              </div>
              <div className='w-70'>
                <Field
                    className={`register-form_field text-large ${
                        formik.errors.phoneNumber2 && formik.touched.phoneNumber2
                            ? "register-form_field-error"
                            : ""
                    }`}
                    type="text"
                    inputMode="numeric"
                    placeholder={t("phoneNumber")}
                    name="phoneNumber2"
                    required
                />
                {formik.errors.phoneNumber2 && formik.touched.phoneNumber2 ? (
                    <Error message={formik.errors.phoneNumber2} />
                ) : null}
              </div>
            </div>
          </div>
          <div className="pt-10">
            <h3 className="text-center text-500">{t("bankInformation")}</h3>
            <div className="pt-20">
              <div className="mb-10">
                <Field
                  className={`register-form_field text-large`}
                  type="text"
                  placeholder={t("cardHolderName")}
                  name="cardHolderName"
                  onChange={(e: any) => {
                    formik.handleChange(e)
                    setIsChangeBankInfo(true)
                  }}
                />
              </div>
              <div className="mb-10">
                <Field
                  className={`register-form_field text-large`}
                  type="text"
                  placeholder={t("iban")}
                  name="iban"
                  onChange={(e: any) => {
                    formik.handleChange(e)
                    setIsChangeBankInfo(true)
                  }}
                />
              </div>
              <div className="mb-20 flex-between-start">
                <div className="w-45">
                  <Field
                    className={`register-form_field text-large w-inherit`}
                    type="text"
                    placeholder={t("bic")}
                    name="bic"
                    onChange={(e: any) => {
                      formik.handleChange(e)
                      setIsChangeBankInfo(true)
                    }}
                  />
                </div>
                <Card className="Card">
                  <CardContent className="padding-0">
                    <div className="flex-center-center pd-10">
                      <input
                        accept="image/*, .pdf"
                        style={{ display: "none" }}
                        id="icon-button-file"
                        type="file"
                        onChange={(e) => handleImageChange(e)}
                      />
                      <label htmlFor="icon-button-file">
                        <IconButton
                          className="orange-button"
                          aria-label="upload picture"
                          component="span"
                        >
                          <PhotoCamera fontSize={"large"} />
                        </IconButton>
                      </label>
                      {!!img.url ? (
                        img.type === TYPE_FILE.IMAGE && (
                          <img
                            className="preview-img"
                            src={img.url}
                            alt={"bank information"}
                          />
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>
              {img.type === TYPE_FILE.PDF && (
                <div className="wrap-preview-pdf">
                  <p className="preview-pdf">{img.url}</p>
                </div>
              )}
            </div>
          </div>
          <div className="flex-center-center mt-20">
            <button
              type="submit"
              className="btn-container btn btn-border-orange btn-white"
            >
              {t("continue")}
            </button>
          </div>
        </form>
      </FormikProvider>
      </div>
      <Snackbar
        open={openAlert.isOpen}
        autoHideDuration={TIME_OUT.THREE_SECOND}
        onClose={() => {
          setOpenAlert({ isOpen: false, message: "" })
        }}
      >
        <Alert severity="error">{t(`${openAlert.message}`)}</Alert>
      </Snackbar>
      <Loading
        isLoading={
          fetchingGetMe ||
          fetchingSignUp ||
          fetchingUpdateMerchantContact ||
          fetchingUpdatePassword ||
          fetchingCheckEmailExisted ||
          fetchingUploadIbanKyc ||
          fetchingCheckUserMolliePromoted ||
          fetchingCreateMollieBank
        }
      />
    </div>
  )
}

export default RegisterPage
