import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { getZones } from "api/places/places.api";
import { placePushNotification } from "api/notification/notification.api";
import { Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { MerchantSelection } from "pages/notification-page/components/MerchantSelection";
import { IZone } from "interfaces/apis/category.inteface";
import toast from 'react-hot-toast';
import classNames from "classnames";

const ruleFormSchema = Yup.object().shape({
  headingsEn: Yup.string().required("Required").max(20, 'Must be exactly 20 digits'),
  headingsFr: Yup.string().required("Required").max(20, 'Must be exactly 20 digits'),
  contentsEn: Yup.string().required("Required").max(200, 'Must be exactly 200 digits'),
  contentsFr: Yup.string().required("Required").max(200, 'Must be exactly 200 digits'),
  groupMessagesEn: Yup.string().required("Required"),
  groupMessagesFr: Yup.string().required("Required"),
  conditionEn: Yup.string().required("Required"),
  conditionFr: Yup.string().required("Required"),
});
export const NotificationPage = () => {
  const { t } = useTranslation()
  const zonePlaceholder = 'Select zones here';
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [listZone, setZones] = useState<IZone[]>([]);
  const [selectedZones, setSelectedZones] = useState<IZone[]>([]);

  const useStyles = makeStyles((theme) => ({
    notifyWrapper: {
      padding: 20
    },
    textField: {
      border: "1px solid lightGrey",
      background: "#f8f8f8",
      padding: "10px 20px 10px 20px",
      width: "100%",
      boxSizing: "border-box",
      borderRadius: 14,
    },
    textFieldError: {
      border: "1px solid red",
    },
    textTruncate: {
      display: 'table',
      tableLayout: 'fixed',
      width: '100%'
    },
    truncate: {
      display: 'table-cell',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  }));
  const classes = useStyles();

  const handleMerchantSelection = (value: IZone[]) => {
    setSelectedZones(value);
  };

  const handleSendNotification = async (values: any) => {
    const { conditionEn, conditionFr, contentsEn, contentsFr, groupMessagesEn, groupMessagesFr, headingsEn, headingsFr } = values || {};
    setLoading(true);
    const headings = [
      {
        language: "en",
        text: headingsEn || '',
      },
      {
        language: "fr",
        text: headingsFr || '',
      },
    ];
    const contents = [
      {
        language: "en",
        text: contentsEn || '',
      },
      {
        language: "fr",
        text: contentsFr || '',
      },
    ];
    const groupMessages = [
      {
        language: "en",
        text: groupMessagesEn || '',
      },
      {
        language: "fr",
        text: groupMessagesFr || '',
      },
    ];
    const conditions = [
      {
        language: "en",
        text: conditionEn || '',
      },
      {
        language: "fr",
        text: conditionFr || '',
      },
    ]
    try {
      const mapZoneIds = selectedZones?.map((it) => it?.id);
      const res = await placePushNotification({
        mapZoneIds: mapZoneIds as string[],
        tableSelection: {
          isSelectedAll: true,
          selectedIdsList: [],
          deselectedIdsList: [],
        },
        notification: {
          headings,
          contents,
          groupMessages,
          conditions,
        },
      });
      setLoading(false);
      toast.success(t('sendNotifySuccess'));
    } catch (error) {
      setLoading(false);
      toast.error(t("Failed"));
    }
  };

  const initialValues = useMemo(
    () => ({
      headingsEn: "",
      headingsFr: "",
      contentsEn: "",
      contentsFr: "",
      groupMessagesEn: "",
      groupMessagesFr: "",
      conditionEn: "",
      conditionFr: ""
    }),
    []
  );

  const formik = useFormik<any>({
    initialValues,
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: ruleFormSchema,
    onSubmit: handleSendNotification,
  });

  const zoneSelectionString = useMemo(() => {
    let result = '';
    selectedZones.forEach((zone, index) => {
      if (selectedZones?.length === 1) {
        result = zone?.name || '';
      } else if (selectedZones?.length && selectedZones?.length > 1) {
        const value = selectedZones[index]?.id !== selectedZones[selectedZones?.length - 1]?.id ? `${zone?.name}, ` : `${zone?.name}`;
        result += value;
      }
    });
    return result
  }, [selectedZones])
  useEffect(() => {
    getZones()
      .then((res) => {
        if (res) {
          setZones(res);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className={classes.notifyWrapper}>
      <FormikProvider value={formik}>

        <div className="mb-10">
          <div className="d-flex align-item-center gap-4 mb-4">
            Select zones
          </div>
          <div onClick={() => setOpen(true)} className={classNames(classes.textTruncate, classes.textField)}>
            <div className={classes.truncate}>
              {!!zoneSelectionString ? zoneSelectionString : zonePlaceholder}
            </div>
          </div>
        </div>

        <div className="mb-10">
          <div className="d-flex align-item-center gap-4 mb-4">
            <span className="text-bold">{t('heading')}</span>
            in
            <span className="text-bold">En</span>
          </div>
          <Field
            className={`${classes.textField} text-large ${formik.errors.headingsEn && formik.touched.headingsEn
              ? `${classes.textFieldError}`
              : ""
              }`}
            type="text"
            name="headingsEn"
            placeholder="How to say it in en?"
            required
          />
          {formik.errors.companyName && formik.touched.companyName ? (
            <Error message={formik.errors.headingsEn as string} />
          ) : null}
        </div>

        <div className="mb-10">
          <div className="d-flex align-item-center gap-4 mb-4">
            <span className="text-bold">{t('heading')}</span>
            in
            <span className="text-bold">Fr</span>
          </div>
          <Field
            className={`${classes.textField} text-large ${formik.errors.headingsFr && formik.touched.headingsFr
              ? `${classes.textFieldError}`
              : ""
              }`}
            type="text"
            name="headingsFr"
            placeholder="How to say it in fr?"
            required
          />
          {formik.errors.headingsFr && formik.touched.headingsFr ? (
            <Error message={formik.errors.headingsFr as string} />
          ) : null}
        </div>

        <div className="mb-10">
          <div className="d-flex align-item-center gap-4 mb-4">
            <span className="text-bold">{t('content')}</span>
            in
            <span className="text-bold">En</span>
          </div>
          <Field
            className={`${classes.textField} text-large ${formik.errors.contentsEn && formik.touched.contentsEn
              ? `${classes.textFieldError}`
              : ""
              }`}
            type="text"
            name="contentsEn"
            placeholder="How to say it in en?"
            required
          />
          {formik.errors.contentsEn && formik.touched.contentsEn ? (
            <Error message={formik.errors.contentsEn as string} />
          ) : null}
        </div>

        <div className="mb-10">
          <div className="d-flex align-item-center gap-4 mb-4">
            <span className="text-bold">{t('content')}</span>
            in
            <span className="text-bold">Fr</span>
          </div>
          <Field
            className={`${classes.textField} text-large ${formik.errors.contentsFr && formik.touched.contentsFr
              ? `${classes.textFieldError}`
              : ""
              }`}
            type="text"
            name="contentsFr"
            placeholder="How to say it in fr?"
            required
          />
          {formik.errors.contentsFr && formik.touched.contentsFr ? (
            <Error message={formik.errors.contentsFr as string} />
          ) : null}
        </div>

        <div className="mb-10">
          <div className="d-flex align-item-center gap-4 mb-4">
            <span className="text-bold">{t('groupMsg')}</span>
            in
            <span className="text-bold">En</span>
          </div>
          <Field
            className={`${classes.textField} text-large ${formik.errors.groupMessagesEn && formik.touched.groupMessagesEn
              ? `${classes.textFieldError}`
              : ""
              }`}
            type="text"
            name="groupMessagesEn"
            placeholder="How to say it in en?"
            required
          />
          {formik.errors.groupMessagesEn && formik.touched.groupMessagesEn ? (
            <Error message={formik.errors.groupMessagesEn as string} />
          ) : null}
        </div>

        <div className="mb-10">
          <div className="d-flex align-item-center gap-4 mb-4">
            <span className="text-bold">{t('groupMsg')}</span>
            in
            <span className="text-bold">Fr</span>
          </div>
          <Field
            className={`${classes.textField} text-large ${formik.errors.groupMessagesFr && formik.touched.groupMessagesFr
              ? `${classes.textFieldError}`
              : ""
              }`}
            type="text"
            name="groupMessagesFr"
            placeholder="How to say it in fr?"
            required
          />
          {formik.errors.groupMessagesFr && formik.touched.groupMessagesFr ? (
            <Error message={formik.errors.groupMessagesFr as string} />
          ) : null}
        </div>

        <div className="mb-10">
          <div className="d-flex align-item-center gap-4 mb-4">
            <span className="text-bold">{t('condition')}</span>
            in
            <span className="text-bold">En</span>
          </div>
          <Field
            className={`${classes.textField} text-large ${formik.errors.conditionEn && formik.touched.conditionEn
              ? `${classes.textFieldError}`
              : ""
              }`}
            type="text"
            name="conditionEn"
            placeholder="How to say it in en?"
            required
          />
          {formik.errors.conditionEn && formik.touched.conditionEn ? (
            <Error message={formik.errors.conditionEn as string} />
          ) : null}
        </div>

        <div className="mb-10">
          <div className="d-flex align-item-center gap-4 mb-4">
            <span className="text-bold">{t('condition')}</span>
            in
            <span className="text-bold">Fr</span>
          </div>
          <Field
            className={`${classes.textField} text-large ${formik.errors.conditionFr && formik.touched.conditionFr
              ? `${classes.textFieldError}`
              : ""
              }`}
            type="text"
            name="conditionFr"
            placeholder="How to say it in fr?"
            required
          />
          {formik.errors.conditionFr && formik.touched.conditionFr ? (
            <Error message={formik.errors.conditionFr as string} />
          ) : null}
        </div>
        <div className="flex-center-center">
          <Button
            disabled={isLoading}
            className="Button btn-orange text-small"
            color="primary"
            variant="contained" onClick={() => formik.handleSubmit()}>
            {t('sendNotify')}
          </Button>
        </div>
      </FormikProvider>

      <MerchantSelection
        selectedMerchant={selectedZones}
        listOption={listZone}
        isOpen={open}
        onToggle={setOpen}
        onSubmitSelection={handleMerchantSelection}
      />
    </div>
  );
};

const Error = (props: { message: string }) => {
  const { t } = useTranslation();
  return <div className="text-red mt-10">{t(`${props.message}`)}</div>;
};
