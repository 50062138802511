import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { orange, grey } from '@material-ui/core/colors';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CodeIcon from '@material-ui/icons/Code';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  customButton: {
    backgroundColor: orange[500],
    color: grey[900],
    display: 'flex',
    justifyContent: 'flex-start'
  },
  wrapperButton: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 20,
    paddingRight: 40,
    paddingLeft: 40
  }
}));

export const MerchantRoute = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();


  const navigateTo = (url: string) => {
    if (url) {
      history.push(url)
    }
  }

  return (
    <div className="pd-10">
      <h3 className="flex-center-center">{t("popularFeature")}</h3>
      <div className={classes.wrapperButton}>
        <Button
          className={classes.customButton}
          variant="contained"
          startIcon={<CodeIcon />}
          onClick={() => history.push('/merchant/dynamic-QR-code')}
        >
          {t("qrLoyalty")}
        </Button>
        <Button
          className={classes.customButton}
          variant="contained"
          startIcon={<NotificationsIcon />}
          onClick={() => navigateTo('/merchant/notification')}
        >
          {t("notification")}
        </Button>
      </div>
    </div>
  );
};
