import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import "./claim-gift-page.css"
import { Trans } from 'react-i18next';
import images from '../../theme/Images';
import { claimGift } from '../../api/loyalty/loyalty.api';

const ClaimGiftPage = () => {
    let query = useQuery();
    const loyaltyProgramId = query.get('loyaltyProgramId');
    const buyerId = query.get('buyerId');
    const [isSuccess, setIsSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        claimGift({
            loyaltyProgramId,
            buyerId
        })
            .then(res => {
                setIsSuccess(true)
            })
            .catch(err => {
                setErrorMessage(err.response.data.message)
            })
    }, []);

    return isSuccess ?
        (
            <div className="confirm-email-container pt-20 text-center">
                <img
                    src={images.cupHolding}
                    alt="congratulation"
                    className="qrCodeImage mb-20"
                />
                <h2 className="text-center text-large text-gray mb-10">
                    <Trans
                        i18nKey="congratulation"
                    />
                </h2>
                <p className="text-medium text-gray text-500">
                    <Trans i18nKey="resetPrizePool" />
                </p>
                <p className="text-medium text-gray text-500">
                    <Trans i18nKey="handOverLot" />
                </p>
            </div>
        ) :
        (
            <div className="confirm-email-container pt-20 text-center">
                <h2 className="text-center text-large text-gray mb-10">
                    {errorMessage}
                </h2>
            </div>
        )
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default ClaimGiftPage
