import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";


export const MerchantSelectionFooter = ({
  onSelectMerchant,
}: {
  onSelectMerchant: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex-center-center">
      <Button
        className="Button btn-orange text-small"
        color="primary"
        variant="contained"
        onClick={onSelectMerchant}
      >
        {t("continue")}
      </Button>
    </div>
  );
}