import axios, { AxiosError, AxiosResponse } from "axios";
import config from "../../common/config";
import { getToken } from "../../common/storage";

export const baseApi = axios.create({
    baseURL: `${config.API_URL}`,
    headers: {
        'x-api-key': 'pWPfDJ72krAmNy4g',
        'language': 'fr'
    }
})

export const getParams = (_params: any) => {
    if (_params) {
        const params = Object.assign(_params, {});
        for (const key in params) {
            if (!params[key] || params[key].length === 0) {
                delete params[key];
            }
        }
    }
    return _params
}

baseApi.interceptors.request.use((config) => {
    const token = getToken()
    if (token) config.headers.common['Authorization'] = 'Bearer ' + token
    return config
})

baseApi.interceptors.response.use(
    (response: AxiosResponse) => {
        return response.data;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);
