import {baseApi} from '../base/base.api'
import {IMapZoneLookup} from "./map-zone.inteface";
import {IMapZoneResponse} from "../../interfaces/apis/map-zone.interface";

const MAP_ZONE_URL = {
    LOOK_UP: '/v1/map-zones/places/lookup'
}

export const mapZoneLookup = (body: IMapZoneLookup) => {
    return baseApi.post<IMapZoneResponse>(MAP_ZONE_URL.LOOK_UP, body)
}
