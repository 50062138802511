import React, { useEffect } from "react"
import "./header.css"
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import images from "../theme/Images"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const routeNotShowIcon = ["/", "/confirm-email"]

const Header = () => {
  const [open, setOpen] = React.useState(false)
  const [isShowIcon, setShowIcon] = React.useState(false)
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const history = useHistory()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onNavigateMerchantLoginPage = () => {
    history.push('/send-verify-code')
  }

  const onBack = () => {
    history.push('/')
  }


  useEffect(() => {
    routeNotShowIcon.includes(pathname) ? setShowIcon(false) : setShowIcon(true)
  }, [pathname, isShowIcon])

  const renderIcon = () => {
    return isShowIcon ? (
      <div className="contact">
        <h4 className="text-center text-white">Aide</h4>
        <div onClick={handleClickOpen} className="clickable">
          <img src={images.phone} alt="phone logo" />
        </div>
      </div>
    ) : (
      <button onClick={onNavigateMerchantLoginPage} className="merchant-login-btn text-medium" >Login</button>
    )
  }

  const renderBackButton = () => {
    return isShowIcon ? (
      <div className="back-btn clickable" onClick={() => {
        onBack();
      }}>
        <ArrowBackIcon className="back-icon" />
      </div>
    ) : null
  }

  return (
    <div className="header-container">
      {renderBackButton()}
      <div className="wrap-header flex-center-center">
        <div className="wrap-logo">
          <img src={images.logo} alt="app logo" className="img-logo" />
        </div>
      </div>
      {renderIcon()}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="text-center"
      >
        <DialogTitle id="alert-dialog-title">{t("callThisNumber")}</DialogTitle>
        <DialogContent>
          <DialogContentText>0179726555</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Header
