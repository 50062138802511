import { useEffect, useRef } from "react";
import axios from "axios";

//Constants
import { COUNTRY_CODE_ISO_MAPPING, COUNTRY_CODE_PHONE } from "../constant/constant";

//Config
import CONFIG from "./config";

//Types
import { ILocation } from "../interfaces/register-page.interface";
import { getItemFromStorage } from "./storage";

export const deepCloneObject = (object: any) => {
    let clonedObject;
    if (typeof object === 'object') {
        clonedObject = JSON.stringify(object)
        return JSON.parse(clonedObject)
    } else return null
}

export const getHereSuggestion = (query: string, location?: ILocation) => {
    const url = `https://autocomplete.search.hereapi.com/v1/autocomplete?at=${location?.lat},${location?.lng}&apiKey=${CONFIG.REACT_APP_HERE_API_KEY}&limit=10&q=${query}`
    if (location) {
        return axios.get(url)
    }
}

export const removeAccents = (str: string) => {
    return str.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, 'd').replace(/Đ/g, 'D');
}

export const getCountryCode = (countryCode: any) => {
    const code = COUNTRY_CODE_ISO_MAPPING[countryCode]
    // @ts-ignore
    return COUNTRY_CODE_PHONE[code]
}

export const getCountryCode2 = (code: any) => {
    // @ts-ignore
    return COUNTRY_CODE_PHONE[code]
}

export const getLanguage = () => {
    return navigator.language.split('-')[0]
}

export const usePrevious = (value: any) => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    }, [value])
    return ref.current
}

export const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}




export const urltoFile = (url: string, filename: string) => {
    if (!url) {
        return
    }
    let arr: any = url.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export const iosLink = 'https://apps.apple.com/vn/app/shoop-city-pro/id1545557250'
export const androidLink = 'https://play.google.com/store/apps/details?id=com.shoopcitycommercant'
