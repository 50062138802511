import { claimQrCode } from "api/loyalty/loyalty.api";
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import "./claim-qr-code.css";
import CachedIcon from '@material-ui/icons/Cached';
import QRCode from "react-qr-code";
import images from "theme/Images";

export const ClaimQrCode = () => {
  const { t } = useTranslation();
  const [qrCode, setQrCode] = useState<string>('');
  useEffect(() => {
    const intervalId = setInterval(() => {
      getQRCode();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [])

  const getQRCode = async () => {
    try {
      const res = await claimQrCode();
      setQrCode(res.data.encodedUrl)
    } catch (error) {
      console.error(error);
    }
  }

  return <div className="qr-loyalty-wrapper">
    <h3 className="qr-title">{t("qrLoyalty")}</h3>
    {
      !!qrCode ? (
        <div className="qr-code">
          <div className="flex-center-center">
            <QRCode
              size={256}
              style={{ height: "80%", width: "80%" }}
              value={qrCode}
              viewBox={`0 0 256 256`}
            />
          </div>
          <p className="text-center">{t("qrChange")}</p>
          <div onClick={getQRCode} className="reset-btn">
            <CachedIcon />
          </div>
        </div>
      ) : (
        <img src={images.notFound} alt="app logo" className="img-logo" />
      )
    }

  </div>;
};
