import {createContext, useContext} from "react";

export type TypeAccountContextType = {
    typeAccount: string,
    setTypeAccount: (type: string) => void
}

export const TypeAccountContext = createContext<TypeAccountContextType>({
    typeAccount: '',
    setTypeAccount: (type: string) => console.warn('No config provider')
})

export const useTypeAccount = () => useContext(TypeAccountContext)
