import { useContext } from "react"
import { Redirect, Route } from "react-router-dom"
import { TypeAccountContext } from "../../context/type-account.context"

// @ts-ignore
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { typeAccount } = useContext(TypeAccountContext)
  return (
    <Route
      {...rest}
      render={(props) => {
        if (typeAccount !== "") return <Component {...props} />
        else return <Redirect to={{ pathname: "/" }} />
      }}
    />
  )
}

export default ProtectedRoute
