import { Redirect, Route } from "react-router-dom"
import { getItemFromStorage } from "../../common/storage"
import { STORAGE_KEY } from "../../constant/constant"

// @ts-ignore
const AuthRoute = ({ component: Component, ...rest }) => {
  const token = getItemFromStorage(STORAGE_KEY.TOKEN)
  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) return <Component {...props} />
        else return <Redirect to={{ pathname: "/" }} />
      }}
    />
  )
}

export default AuthRoute
