import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { RootState } from "redux/reducers/RootReducer";

// @ts-ignore
const ProtectedMerchantRoute = ({ component: Component, ...rest }) => {
  const merchantToken = useSelector(
    (state: RootState) => state.MerchantReducer.merchantAccessToken
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!!merchantToken) return <Component {...props} />;
        else return <Redirect to={{ pathname: "/" }} />;
      }}
    />
  );
};

export default ProtectedMerchantRoute;
