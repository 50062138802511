import React, { useState } from "react";
import OtpInput from "react-otp-input";
import "./verifyOtp.css";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { placeSignIn } from "api/auth/place/auth-place.api";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MerchantAction } from "redux/actions";
import classNames from "classnames";
import { setToken } from "common/storage";
import { handleError } from "api/error/handle-error";
import toast from 'react-hot-toast';

export const VerifyOtp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [otpInput, setOtpInput] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    const { codeId, placeId } = (location.state as any) || {};
    setLoading(true);
    placeSignIn({
      code: otpInput,
      codeId: codeId || "",
      placeId: placeId || "",
    }).then(res => {
      if (res) {
        dispatch(MerchantAction.merchantSignIn(res?.accessToken || null));
        setToken(res?.accessToken || null)
        setLoading(false);
        history.push("/merchant-page");
      } else {
        setLoading(false);
      }
    }).catch(error => {
      handleError(error);
      toast.error(t("invalidCode"));
    })
  };

  return (
    <div className="otp-container pt-20">
      <div className="mb-auto">
        <div className="otp-title text-center text-bold">
          <h3 className="text-large text-center text-bold">
            {" "}
            {t("enterOtpCode")}{" "}
          </h3>
        </div>
        <div className="otp-code mt-20 mb-20 flex-center-center flex-no-wrap">
          <OtpInput
            value={otpInput}
            onChange={(otp: string) => setOtpInput(otp)}
            numInputs={6}
            inputStyle="otp-input"
            containerStyle={{ justifyContent: "center", minWidth: "50px" }}
            isInputNum={true}
          />
        </div>
      </div>

      <div className="otp-actions flex-center-center ">
        <br />
        <Button
          disabled={isLoading}
          className={classNames("Button text-large", {
            "Button-disable": isLoading,
            "Button-active": !isLoading,
          })}
          color="primary"
          variant="contained"
          onClick={onSubmit}
        >
          {t("validate")}
        </Button>
      </div>
    </div>
  );
};
