import { handleError } from "api/error/handle-error";
import { IClaimQRCodeResponse } from "api/loyalty/loyalty.interface";
import { AxiosResponse } from "axios";
import { baseApi } from "../base/base.api";

export const LOYALTY_API = {
  GIFT: "/v1/loyalty/buyer/gift",
  QR_CODE: "v1/loyalty/merchant/place-qr",
};

export const claimGift = (body: {
  loyaltyProgramId: string | null;
  buyerId: string | null;
}): Promise<AxiosResponse<any>> => {
  return baseApi.post<any>(LOYALTY_API.GIFT, body);
};

export const claimQrCode = (): Promise<AxiosResponse<IClaimQRCodeResponse>> => {
  return baseApi.get<IClaimQRCodeResponse>(LOYALTY_API.QR_CODE);
};
