import { IRegister, IRegisterModel } from "../interfaces/register-page.interface";
import { getLanguage } from "../common/helper";

export class RegisterModel {
    public register: IRegisterModel
    private _regis: IRegister

    constructor(regis: IRegister, imageId?: string) {
        this._regis = regis;
        this.register = {
            email: regis.email,
            password: regis.password,
            type: regis.type,
            preferredLanguage: getLanguage(),
            manager: {
                email: regis.email,
                country: regis.country,
                street: regis.street ? regis.street : '',
                phone: {
                    countryCode: +regis.countryNumberCode2,
                    phoneNumber: regis.phoneNumber2
                },
                firstName: regis.firstName,
                lastName: regis.lastName,
                fullName: this._fullName(),
                gender: regis.isMale ? "male" : "female"
            },
            society: {
                email: regis.email,
                country: regis.country,
                street: regis.street ? regis.street : '',
                phone: {
                    countryCode: +regis.countryNumberCode,
                    phoneNumber: regis.phoneNumber
                },
                name: regis.tradeName,
                tradeName: regis.companyName,
                address: regis.channel,
                legalStatus: regis.legalStatus
            },
            shopContact: {
                email: regis.email,
                country: regis.country,
                street: regis.street ? regis.street : '',
                phone: {
                    countryCode: +regis.countryNumberCode,
                    phoneNumber: regis.phoneNumber
                },
                name: regis.tradeName
            },
            location: {
                ...regis.location
            },
            googlePlaceId: regis.googlePlaceId,
            bankAccount: {
                name: regis.cardHolderName,
                BIC: regis.bic,
                IBAN: regis.iban,
                kycDocumentId: imageId
            },
        }
    }

    private _fullName() {
        return this._regis.firstName + this._regis.lastName
    }

    get bankInfo() {
        return this.register.bankAccount
    }

    get merchantInfo() {
        return {
            manager: {
                ...this.register.manager
            },
            society: {
                ...this.register.society
            },
            googlePlaceId: this.register.googlePlaceId
        }
    }
}
