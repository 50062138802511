import { baseApi, getParams } from '../base/base.api'
import { handleError } from "../error/handle-error";
import { AxiosResponse } from "axios";
import { INotificationPayload } from 'api/notification/notification.interface';

const NOTIFICATION_URL = {
  NOTIFICATIONS: '/v1/admin/notifications'
}

export const placePushNotification = (body: INotificationPayload): Promise<any> => {
  return baseApi.post<any>(`${NOTIFICATION_URL.NOTIFICATIONS}/place-push`, body)
}