import React, {useContext, useState} from 'react'
import {Trans, useTranslation} from "react-i18next";
import {RegisterInfoContext} from "../../context/register-context";
import {useHistory} from "react-router-dom";
import {IMapZoneLookup} from "../../api/map-zones/map-zone.inteface";
import {mapZoneLookup} from "../../api/map-zones/map-zone.api";

// CSS
import './confirm-email.css'
import {checkVerify} from "../../api/merchant/merchant.api";
import {Snackbar} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {ERROR, TIME_OUT} from "../../constant/constant";
import {ErrorState, handleError} from "../../api/error/handle-error";

const ConfirmEmailPage = () => {
    const {registerInfo} = useContext(RegisterInfoContext)
    const [openAlert, setOpenAlert] = useState({isOpen: false, message: ''})
    const {t} = useTranslation()
    const history = useHistory()

    const errorState = ErrorState.getInstance()

    const toPromo = async () => {
        try {
            const res = await checkVerify()
            if (res.data.isValidateEmail) {
              history.push('/otp')
            } else {
                setOpenAlert({isOpen: true, message: ERROR.EMAIL_NOT_VERIFY})
            }
        } catch (error) {
            handleError(error, () => history.push('/'))
            setOpenAlert({isOpen: true, message: errorState.getErrorText()})
        }
    }

    return (
        <div className="confirm-email-container pt-20 text-center">
            <h2 className="text-center text-large text-gray mb-20">
                <Trans
                    i18nKey="confirmEmail"
                />
            </h2>
            <span className="text-medium text-gray text-500">
                <Trans i18nKey="sendEmailTo" values={{userMail: registerInfo.email}}/>
            </span>
            <br/>
            <span className="text-medium text-gray text-500">
                 <Trans i18nKey="checkMailBox"/>
            </span>
            <button onClick={toPromo} className="btn btn-container btn-orange mt-20">
                {t('continue')}
            </button>
            <Snackbar
                open={openAlert.isOpen}
                autoHideDuration={TIME_OUT.THREE_SECOND}
                onClose={() => {
                    setOpenAlert({isOpen: false, message: ''})
                }}
            >
                <Alert severity="error">
                    {t(`${openAlert.message}`)}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ConfirmEmailPage
