import { IZipCodeCity } from '../interfaces/register-page.interface';
import React, { Dispatch, SetStateAction } from 'react';

export const initialZipCodeCity: IZipCodeCity = {
  channel: '',
  country: '',
  countryNumberCode: '',
  countryNumberCode2: '',
  street: '',
  address: '',
  googlePlaceId: '',
  countryCode: 0,
}

export const ZipCodeCityContext: React.Context<{ zipCodeCityInfo: IZipCodeCity, setZipCodeCityInfo?: Dispatch<SetStateAction<IZipCodeCity>> }> = React.createContext({
  zipCodeCityInfo: initialZipCodeCity,
})
