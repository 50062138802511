import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react"
import Card from "../../components/card/Card"
import { Trans, useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { setStorage } from "../../common/storage"
import {
  ERROR, OFFER_TYPE, REGEX,
  STORAGE_KEY,
  TIME_OUT, TYPE_ACCOUNT,
} from "../../constant/constant"
import { getPromoCodes, IPromoCode, validatePromoCode } from "../../api/promotion/promotion.api"
import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
// CSS
import "./main-page.css"
import { IOfferSubscription } from "../../interfaces/offer-subscription.interface";
import { Field, useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { IZipCodeCity } from '../../interfaces/register-page.interface';
import _ from 'lodash';
import { getCountryCode2 } from '../../common/helper';
import { ZipCodeCityContext } from '../../context/zip-code-city-context';
import { TypeAccountContext } from '../../context/type-account.context';
import { RegisterInfoContext } from '../../context/register-context';
import { TypeOfferContext } from '../../context/type-subscribtion.context';
import Autocomplete from "react-google-autocomplete";
import { googleApiKey } from "constant/config.constant";

const RegisterSchema = Yup.object().shape({
  channel: Yup.string().required("Required")
})

const Error = (props: { message: string }) => {
  const { t } = useTranslation()
  return <div className="text-red mt-10">{t(`${props.message}`)}</div>
}
const MainPage = () => {
  const { t } = useTranslation()
  const { type } = useParams<{ type: string }>()
  const [placeInfo, setPlace] = useState<any>({})
  const { zipCodeCityInfo, setZipCodeCityInfo } = useContext(ZipCodeCityContext)
  const { registerInfo, setRegisterInfo } = useContext(RegisterInfoContext)
  const { typeAccount, setTypeAccount } = useContext(TypeAccountContext)
  const history = useHistory()
  const [promoCode, setPromoCode] = useState("")
  const [promoCodeResponse, setPromoCodeResponse] = useState<IOfferSubscription>()
  const [option1, setOption1] = useState(false)
  const [option2, setOption2] = useState(false)
  const [openAlert, setOpenAlert] = useState({ isOpen: false, message: "" })
  const [promoCodes, setPromoCodes] = useState<IPromoCode[]>([]);
  const [selectedPromo, setSelectPromo] = useState<IPromoCode>()
  const formRef = useRef<any>();
  const inputRef = useRef(null);

  useEffect(() => {
    getPromoCodeApi();
  }, [])

  const getPromoCodeApi = async () => {
    
    try {
      const res = await getPromoCodes();
      if (res && Array.isArray(res.data)) {
        setPromoCodes(res.data as IPromoCode[])
      }

    } catch (error) {
      console.error(error);
    }
  }

  const formik = useFormik({
    initialValues: zipCodeCityInfo,
    onSubmit: values => {
      onSubmit(values);
    },
    validationSchema: RegisterSchema,
    enableReinitialize: true,
    innerRef: formRef
  });

  const toOtpPage = () => {
    history.push("/otp")
  }

  const handleSubmit = async () => {
    if(option1 && option2) {
      formik.handleSubmit();
    } else if (option1) {
      history.push('/register')
    }
  }

  const onSubmit = async (values: IZipCodeCity) => {
    if (!promoCodeResponse) {
      if (option2) {
        const formValues = {
          ..._.cloneDeep(values),
          ...placeInfo
        }
        const value: IZipCodeCity = {
          ..._.cloneDeep(formValues),
        }
        // SAVE INFO TO LOCAL & UPDATE FORM FIELD
        //@ts-ignore
        setZipCodeCityInfo(value)
        const registerInfo2 = {
          ...registerInfo,
          ...value
        }
        //@ts-ignore
        setRegisterInfo({
          ...registerInfo2,
          saveFromStep1: true,
        })
        setStorage(STORAGE_KEY.ZIP_CODE_CITY, value)
        validatePromoCode(promoCode, formValues.googlePlaceId, selectedPromo?.id || '').then((res) => {
          if (!res.data.id) {
            setOpenAlert({ isOpen: true, message: ERROR.INVALID_PROMO_CODE })
          } else {
            setStorage(STORAGE_KEY.PROMO_CODE, promoCode)
            setStorage(STORAGE_KEY.PROMO_CODE_ID, res.data.id)
            setPromoCodeResponse(res.data)
          }
        })
            .catch(err => {
              setOpenAlert({ isOpen: true, message: err.message })
            })
      } else {
        toOtpPage()
      }
    } else {
      history.push('/register')
      setOption2(false)
    }
  }

  const handlePromoCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value)
  }

  const renderButton = () => {
    return (
      <button
          type="submit"
          onClick={handleSubmit}
          className="btn-container btn btn-orange">
        <span className="text-medium">{t("validateMyChoice")}</span>
      </button>
    )
  }

  const handleSuggestClick = (selectedValue: any) => {
    const address = selectedValue.address_components;
    let newRegisterInfo: any = {}
    newRegisterInfo = {
      ...newRegisterInfo,
      channel: selectedValue?.formatted_address,
      countryCode: getCountryCode2(address?.find((it: any) => it?.types?.includes('country'))?.short_name ?? ''),
      countryNumberCode: getCountryCode2(address?.find((it: any) => it?.types?.includes('country'))?.short_name ?? ''),
      countryNumberCode2: getCountryCode2(address?.find((it: any) => it?.types?.includes('country'))?.short_name ?? ''),
      street: address?.find((it: any) => it?.types?.includes('route'))?.long_name,
      country: address?.find((it: any) => it?.types?.includes('country'))?.long_name,
      googlePlaceId: selectedValue?.place_id,
    }
    setPlace(newRegisterInfo)
  }

  const renderPromoInput = () => {
    return (
      <React.Fragment>
        <div className="mb-20 promo-input">
          <input
              value={promoCode}
              onChange={(e) => {
                handlePromoCodeChange(e)
              }}
              className="form_field text-medium"
              type="text"
              placeholder="CODE PROMO"
              required
          />
        </div>
        <FormikProvider value={formik} >
          <form  onSubmit={formik.handleSubmit}>
            <div className="mb-10">
              <Field
                name="channel"
                render={({ field, form: { touched, errors } }: any) => (
                  <>
                    <Autocomplete
                      {...field}
                      className={`register-form_field text-large ${
                        errors[field.name] && touched.channel
                            ? "register-form_field-error"
                            : ""
                      }`}
                      ref={inputRef}
                      placeholder={t("channel")}
                      apiKey={googleApiKey}
                      onPlaceSelected={(selected: any) => {
                        formik.handleChange(selected?.formatted_address)
                        formik.setFieldValue("channel", selected?.formatted_address)
                        handleSuggestClick(selected)
                      }}
                      options={{
                        types: ["geocode", "establishment"],
                        componentRestrictions: "us",
                      }}
                    />
                    {touched[field.name] && errors[field.name] && <Error message={errors[field.name]} />}
                  </>
                )}
              />
            </div>
          </form>
        </FormikProvider>
      </React.Fragment>
    )
  }

  const renderPrice = (originPrice: string, offerPrice: string) => {
    return +originPrice - +offerPrice
  }

  const handleChangeAccountType = (promo: IPromoCode) => {
    setSelectPromo(promo)
    setTypeAccount(promo?.type || TYPE_ACCOUNT.product.value)
  }

  const checkCurrentPromo = (currentPromo: any) => {
    return currentPromo?.id === selectedPromo?.id
  }

  const renderHeader = () => {
    // if (type === "149")
      return <h2 className="title text-large">{t("choiceOfOffer")}</h2>
    // else
    //   return (
    //     <div className="flex-center-center">
    //       <h2 className="title text-large">{t("promotionOffer")}</h2>
    //       <img src={images.check} alt="icon" />
    //     </div>
    //   )
  }

  const renderCard = () => {
    if (!promoCodeResponse) {
      return (
      <React.Fragment>
        {promoCodes?.length > 0 && promoCodes?.map((promo, index: number) => (
          <Card
            key={index}
            onClick={() => handleChangeAccountType(promo)}
            header={{
              content: (
                  <h2 className="text-orange text-large">{promo?.title}</h2>
              ),
              isShowCheckLogo: checkCurrentPromo(promo),
            }}
            body={{
              content: (
                <span className="content text-medium">
                  <span>{promo?.detail}</span>
                  <br></br>
                  <span>{promo?.price?.value}€</span>
                </span>
              ),
            }}
            footer={{
              isDisplay: !!promo?.detailInCaseOf,
              description: promo?.detailInCaseOf
            }}
          />
        ))}
      </React.Fragment>
      )
    } else {
      return (
        <Card
          header={{
            content: (
              <h2 className="text-large text-center">
                {promoCodeResponse?.title}
                <span className="text-red text-strike">
                  {promoCodeResponse?.originalPrice.value}€
                </span>
                <span className="text-green">
                  {
                    (promoCodeResponse?.offerSubscriptionPrice.value || '0')
                  }€</span> HT
              </h2>
            ),
            isShowCheckLogo: false,
          }}
          body={{
            content: (
              <div>
                <h4 className="text-medium text-uppercase text-light-green mb-20" dangerouslySetInnerHTML={{__html: promoCodeResponse?.content || ''}}>
                </h4>
                <span className="text-medium text-bold" dangerouslySetInnerHTML={{__html: promoCodeResponse?.subContent || ''}}>
                </span>
                <br />
                <br />
                <span className="text-gray text-small" dangerouslySetInnerHTML={{__html: promoCodeResponse?.footer || ''}}>
                </span>
              </div>
            ),
          }}
          
        />
      )
    }
  }

  return (
    <div className="main-page pt-20">
      <div className="main-page-header text-center">{renderHeader()}</div>
      <div className="main-page-body mb-20">
        {renderCard()}
        <div>
          <form className="form-control text-center">
            <input
              type="checkbox"
              id="option1"
              name="option1"
              checked={option1}
              onChange={() => setOption1(!option1)}
            />
            <label
              htmlFor="option1"
              className="option1 text-gray text-medium ml-10"
            >
              <Trans
                i18nKey="checkBox1"
                values={{
                  link1:
                    '<a className="text-gray" href="https://shoopcity.com/cgupsc.html" target="_blank">CGUPSC</a>',
                  link2:
                    '<a className="text-gray" href="https://shoopcity.com/cgfs.html" target="_blank">CGFS</a>',
                  link3: `<a className="text-gray" href="https://www.shoopcity.com/confidentialite.pdf" target="_blank">${t(
                    "privacy"
                  )}</a>`,
                  link4:
                    '<a className="text-gray" href="https://www.mollie.com/en/user-agreement" target="_blank">MOLLIE</a>',
                  link5:
                    '<a className="text-gray" href="https://www.mangopay.com/privacy/" target="_blank">MANGOPAY</a>',
                }}
                components={{
                  a: <a className="text-underline" />,
                }}
              />
            </label>
            <br />
            {type !== "0" ? (
              <React.Fragment>
                <input
                  type="checkbox"
                  id="option2"
                  name="option2"
                  checked={option2}
                  onChange={() => setOption2(!option2)}
                />
                <label htmlFor="option2" className="option2 text-medium ml-10">
                  {t("checkBox2")}
                </label>
                <br />
              </React.Fragment>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
      <div className="main-page-actions flex-center-center flex-col">
        {option2 ? renderPromoInput() : <></>}
        {option1 ? renderButton() : <></>}
      </div>
      <Snackbar
        open={openAlert.isOpen}
        autoHideDuration={TIME_OUT.THREE_SECOND}
        onClose={() => {
          setOpenAlert({ isOpen: false, message: "" })
        }}
      >
        <Alert severity="error">{t(`${openAlert.message}`)}</Alert>
      </Snackbar>
    </div>
  )
}

export default MainPage
