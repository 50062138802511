import { ChangeEvent, useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import _ from "lodash"
//Actions
import { checkVerify, updateMerchant } from "../../api/merchant/merchant.api"
import { createOtp, resendOtp, validateOtp } from "../../api/otp/otp.api"

//Functions
import { ErrorState, handleError } from "../../api/error/handle-error"
import { getItemFromStorage, setStorage } from "../../common/storage"

//Components
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import OtpInput from "react-otp-input"

//Constants
import {
  ERROR,
  OTP_TIMEOUT,
  REGEX,
  STORAGE_KEY,
  TIME_OUT,
} from "../../constant/constant"

//Language
import { Trans, useTranslation } from "react-i18next"

//CSS
import "./otp-page.css"

//Types
import { RegisterInfoContext } from "../../context/register-context"
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

const validatePhoneNumber = (
  checkingPhoneNumber: string
): { isInputValid: boolean; errorMessage: string } => {
  const isValid = isValidPhoneNumber(checkingPhoneNumber)
  if (!isValid) {
    return {
      isInputValid: false,
      errorMessage: "Invalid phone number",
    }
  }
  else {
    return {
      isInputValid: true,
      errorMessage: "",
    }
  }
}

const ErrorMessage = (props: { isHidden: boolean; errorMessage: string }) => {
  const { t } = useTranslation()
  const { isHidden, errorMessage } = props
  if (isHidden) return null
  return (
    <div className="text-yellow text-small text-center mt-10">
      {t(`${errorMessage}`)}
    </div>
  )
}

const OtpPage = () => {
  const { t } = useTranslation()

  const { registerInfo, setRegisterInfo } = useContext(RegisterInfoContext)

  const history = useHistory()

  const [codeTime, setCodeTime] = useState<number>(OTP_TIMEOUT)
  const [openChangeNumberModal, setChangeNumberModal] = useState(false)
  const [phoneNumber, setChangeNumber] = useState({
    value: "",
    isInputValid: true,
    errorMessage: "",
  })
  const [isValidatePhoneNumber, setValidatePhoneNumber] = useState(false)
  const [openAlert, setOpenAlert] = useState({
    isOpen: false,
    message: "",
  })
  const [otpInput, setOtpInput] = useState<string>("")

  // Error
  const errorState = ErrorState.getInstance()

  useEffect(() => {
    let timer = setTimeout(() => {
      if (codeTime > 0) {
        let newCodeTime = codeTime - 1
        setCodeTime(newCodeTime)
      }
    }, TIME_OUT.ONE_SECOND)
    return () => {
      clearTimeout(timer)
    }
  }, [codeTime])

  useEffect(() => {
    checkVerify()
      .then((res) => {
        setValidatePhoneNumber(res.data.isValidatePhone)
        if (!res.data.isValidatePhone) {
          createOtp()
            .then((res) => {
              setStorage(STORAGE_KEY.OTP, res.data)
            })
            .catch((error) => {
              setOpenAlert({
                isOpen: true,
                message: error.response.data.message,
              })
            })
        }
      })
      .catch((error) => {
        setOpenAlert({
          isOpen: true,
          message: "Forbidden",
        })
      })
  }, [isValidatePhoneNumber])

  const handleNumberModalClose = () => {
    setChangeNumberModal(false)
    setChangeNumber({
      value: "",
      isInputValid: true,
      errorMessage: "",
    })
  }

  const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChangeNumber({
      ...phoneNumber,
      value: event.target.value,
    })
  }

  const handlePhoneNumberValidation = () => {
    const { isInputValid, errorMessage } = validatePhoneNumber(
      phoneNumber.value
    )
    setChangeNumber({
      ...phoneNumber,
      isInputValid,
      errorMessage,
    })
  }

  const onSubmit = () => {
    let item = getItemFromStorage(STORAGE_KEY.OTP)
    // @ts-ignore
    const otp = JSON.parse(item)

    // 6 is Verification code length
    if (otpInput.length < 6) {
      setOpenAlert({ isOpen: true, message: ERROR.FULL_FILLED_CODE })
    } else {
      validateOtp({ codeId: otp.id, userInputCode: otpInput })
        .then((res) => {
          history.push("/success-register")
        })
        .catch((error) => {
          handleError(error, () => history.push("/"))
          setOpenAlert({ isOpen: true, message: errorState.getErrorText() })
        })
    }
  }

  const handleResendOtpClick = () => {
    setOtpInput("")
    const item = getItemFromStorage(STORAGE_KEY.OTP)
    setCodeTime(OTP_TIMEOUT)
    if (item) {
      const otp = JSON.parse(item)
      resendOtp(otp.id)
        .then((res) => {
          setStorage(STORAGE_KEY.OTP, res.data)
        })
        .catch((error) => {
          setOpenAlert({
            isOpen: true,
            message: error.response.data.message || error.message,
          })
        })
    }
  }

  const handleChangeNumberClick = () => {
    if (phoneNumber.isInputValid && phoneNumber.value !== "") {
      const {countryCallingCode, nationalNumber} = parsePhoneNumber(phoneNumber.value)
      // @ts-ignore
      updateMerchant({
        manager: {
          phone: {
            countryCode: +countryCallingCode,
            phoneNumber: nationalNumber,
          },
        },
      }).then((res) => {
        createOtp()
          .then((res) => {
            setStorage(STORAGE_KEY.OTP, res.data)
            setCodeTime(OTP_TIMEOUT)
            if (!_.isEmpty(registerInfo)) {
              // @ts-ignore
              setRegisterInfo({
                ...registerInfo,
                phoneNumber2: phoneNumber.value,
              })
            }
          })
          .catch((error) => {
            setOpenAlert({
              isOpen: true,
              message: error.message,
            })
          })
      })
      handleNumberModalClose()
      setOtpInput("")
    }
  }

  return (
    <div className="otp-container pt-20">
      <div className="otp-body mb-auto">
        <div className="otp-title text-center text-bold">
          <h3 className="text-large text-center text-bold">
            {" "}
            {t("enterOtpCode")}{" "}
          </h3>
          <span className="subtitle text-medium">
            {t("smsSendTo")}&nbsp;
            <span className="text-underline">{registerInfo.phoneNumber2}</span>
          </span>
        </div>
        <div className="otp-code mt-20 mb-20 flex-center-center flex-no-wrap">
          <OtpInput
            value={otpInput}
            onChange={(otp: string) => setOtpInput(otp)}
            numInputs={6}
            inputStyle="otp-input"
            containerStyle={{ justifyContent: "center", minWidth: "50px" }}
            isInputNum={true}
          />
        </div>
        <div className="otp-content text-center mt-20">
          {codeTime > 0 ? (
            codeTime
          ) : (
            <Trans
              i18nKey="codeNotReceived"
              components={{
                span: (
                  <span
                    onClick={handleResendOtpClick}
                    className="text-underline clickable"
                  />
                ),
                p: <p />,
              }}
            />
          )}
          <Trans
            i18nKey="numberError"
            components={{
              span: (
                <span
                  onClick={() => setChangeNumberModal(true)}
                  className="text-underline clickable"
                />
              ),
              p: <p />,
            }}
          />
        </div>
        <Dialog
          onClose={handleNumberModalClose}
          aria-labelledby="customized-dialog-title"
          open={openChangeNumberModal}
        >
          <div className="bg-dark-gray">
            <DialogTitle
              className="text-center text-uppercase text-medium text-white"
              id="customized-dialog-title"
            >
              {t("changeNumber")}
            </DialogTitle>
            <DialogContent>
              <input
                value={phoneNumber.value}
                onChange={(e) => handleNumberChange(e)}
                className="otp-form_field text-center text-medium"
                placeholder={t("phoneNumber")}
                onBlur={handlePhoneNumberValidation}
                inputMode="numeric"
              />
              <ErrorMessage
                isHidden={phoneNumber.isInputValid}
                errorMessage={phoneNumber.errorMessage}
              />
            </DialogContent>
            <div className="wrapButton">
              <button
                onClick={handleChangeNumberClick}
                className="btn-container btn btn-gray mb-10 buttonOk"
              >
                <span className="text-500">OK</span>
              </button>
              <button
                onClick={handleNumberModalClose}
                className="btn-container btn btn-gray mb-10 buttonOk ml-0"
              >
                <span className="text-500">{t("cancel")}</span>
              </button>
            </div>
          </div>
        </Dialog>
      </div>
      <div className="otp-actions flex-center-center ">
        <br />
        <Button
          className={`Button Button-active text-large`}
          color="primary"
          variant="contained"
          onClick={onSubmit}
        >
          {t("validate")}
        </Button>
      </div>
      <Snackbar
        open={openAlert.isOpen}
        autoHideDuration={TIME_OUT.FIVE_SECOND}
        onClose={() => {
          setOpenAlert({ isOpen: false, message: "" })
        }}
      >
        <Alert severity="error">{t(`${openAlert.message}`)}</Alert>
      </Snackbar>
    </div>
  )
}

export default OtpPage
