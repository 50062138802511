import { baseApi } from '../base/base.api'
import { AxiosResponse } from "axios";
import { IMerchantContact, IRegisterModel } from "../../interfaces/register-page.interface";
import { IImageResponse } from "../../interfaces/kyc.interface";
import { TYPE_KYC } from "../../constant/constant";
import { IBank } from "../../interfaces/bank.interface";

export const MERCHANTS_URL = {
    CHECK_EMAIL: '/v1/auth/merchants/check-email',
    SIGN_UP: '/v1/auth/merchants/sign-up',
    UPDATE_MERCHANT: '/v1/users/merchants/contacts',
    VERIFY: '/v1/auth/verify',
    IMG_UPLOAD: '/v1/images/multiple',
    CHECK_VERIFY: '/v1/users/merchants/check-verify',
    CREATE_SUBSCRIPTION: '/v1/subscriptions/first-subscription',
    CREATE_OFFER_SUBSCRIPTION: '/v1/subscriptions/first-offer-subscription',
    CREATE_BANK: '/v1/bank-accounts',
    CHANGE_PASSWORD: '/v1/users/password',
    CHECK_USER_MOLLIE_PROMOTED: '/v1/map-zones/places/lookup',
    UPLOAD_IBAN_KYC: '/v1/kyc/uploads/iban',
    GET_ME: 'v1/users/merchants/me'
}

export const checkEmailExisted = (email: string): Promise<AxiosResponse<{ isExisted: boolean }>> => {
    return baseApi.post<{ isExisted: boolean }>(MERCHANTS_URL.CHECK_EMAIL, { email })
}

export const signUpAll = (allInfo: IRegisterModel) => {
    return baseApi.post(MERCHANTS_URL.SIGN_UP, allInfo)
}

export const updatePhoneNumber = (numberDto: { phoneNumber: string }) => {
    return baseApi.post(MERCHANTS_URL.UPDATE_MERCHANT, numberDto)
}

export const checkVerify = (): Promise<AxiosResponse<{ isValidateEmail: boolean, isValidatePhone: boolean }>> => {
    return baseApi.get<{ isValidateEmail: boolean, isValidatePhone: boolean }>(MERCHANTS_URL.CHECK_VERIFY, {})
}

export const createSubscription = (body?: { offerSubscriptionId?: string, importedPlaceId?: string }) => {
    return baseApi.post(MERCHANTS_URL.CREATE_OFFER_SUBSCRIPTION, { ...body, isAcceptCondition: true })
}

export const uploadBankImg = (selectedImg: any): Promise<AxiosResponse<IImageResponse>> => {
    const formData = new FormData()
    formData.append('files', selectedImg)
    formData.append('type', TYPE_KYC.BANK_RIB)
    return baseApi.post<IImageResponse>(MERCHANTS_URL.IMG_UPLOAD, formData)
}

export const createBank = (bankInfo: IBank) => {
    return baseApi.post(MERCHANTS_URL.CREATE_BANK, bankInfo)
}

export const updateMerchant = (merchantInfo: IMerchantContact) => {
    return baseApi.put(MERCHANTS_URL.UPDATE_MERCHANT, merchantInfo)
}

export const changePassword = (changePasswordBody: {
    newPassword: string,
    oldPassword: string,
    isForceLogOutAllDevices: boolean
}) => {
    return baseApi.put(MERCHANTS_URL.CHANGE_PASSWORD, changePasswordBody)
}
