import { useEffect, useState } from "react"
import { useHistory } from "react-router"

//Functions
import { ErrorState, handleError } from "../../api/error/handle-error"
import { clearStorage, getItemFromStorage } from "../../common/storage"
import { androidLink, iosLink } from "common/helper"

//Apis
import { createSubscription } from "../../api/merchant/merchant.api"
import { mapZoneLookup } from "../../api/map-zones/map-zone.api"

//Components
import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"

//Styles
import "./success-register.css"
import images from "theme/Images"

//Languages
import { Trans, useTranslation } from "react-i18next"

//Constants
import { STORAGE_KEY, TIME_OUT } from "../../constant/constant"

//Types
import { IRegister } from "../../interfaces/register-page.interface"
import { IMapZoneLookup } from "../../api/map-zones/map-zone.inteface"

const SuccessRegisterPage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [isCreatedSubscription] = useState(false)
  const [openAlert, setOpenAlert] = useState({
    isOpen: false,
    message: "",
  })

  // Error
  const errorState = ErrorState.getInstance()

  const onClickLabel = (type: string) => () => {
    if (type === "android") {
      window.open(androidLink)
    } else {
      window.open(iosLink)
    }
  }

  const onClickReturnHome = () => {
    history.replace("/")
    window.location.reload()
  }

  useEffect(() => {
    const promoCode = getItemFromStorage(STORAGE_KEY.PROMO_CODE)
    const promoCodeId = getItemFromStorage(STORAGE_KEY.PROMO_CODE_ID)
    let item = getItemFromStorage(STORAGE_KEY.REGISTER)
    if (item) {
      let registerInfo: IRegister = JSON.parse(item)
      const iMapZone: IMapZoneLookup = {
        phones: [
          {
            countryCode: registerInfo.countryCode,
            phoneNumber: registerInfo.phoneNumber,
          },
          {
            countryCode: registerInfo.countryCode,
            phoneNumber: registerInfo.phoneNumber2,
          },
        ],
      }

      mapZoneLookup(iMapZone).then((res) => {
        const userData = JSON.parse(getItemFromStorage(STORAGE_KEY.USER_DATA) + '')
        createSubscription({
            offerSubscriptionId: promoCodeId,
          importedPlaceId: userData?.user?.place?.id,
        })
          .then((res) => {
            clearStorage()
          })
          .catch((error) => {
            handleError(error)
            setOpenAlert({ isOpen: true, message: errorState.getErrorText() })
          })
      })
    }
  }, [isCreatedSubscription])

  return (
    <div className="success-register-container">
      <img
        src={images.validate}
        className="validateImage mb-20"
        alt="validate"
      />
      <h2 className="text-center text-large text-gray mb-20">
        <Trans i18nKey="thankYou" components={{ br: <br /> }} />
      </h2>

      <h2 className="text-center text-medium text-gray mb-20 downloadText">
        <Trans i18nKey="download" components={{ br: <br /> }} />
      </h2>
      <div className="flex-center-center">
        <div className="wrap-qrCode">
          <img src={images.qrCodeIos} alt="qr code" className="qrCodeImage" />
          <img
            src={images.labelIos}
            alt="label ios"
            className="labelImage"
            onClick={onClickLabel("ios")}
          />
        </div>
        <div className="wrap-qrCode">
          <img
            src={images.qrCodeAnroid}
            alt="qr code"
            className="qrCodeImage"
          />
          <img
            src={images.labelAndroid}
            alt="label android"
            className="labelImage"
            onClick={onClickLabel("android")}
          />
        </div>
      </div>
      <h2 className="text-center text-small text-gray mb-20 downloadText mt-20 text-500">
        <Trans i18nKey="qrCode" components={{ br: <br /> }} />
      </h2>
      <button
        className="pd-10 button-back text-large"
        onClick={onClickReturnHome}
      >
        <Trans i18nKey="returnToHome" components={{ br: <br /> }} />
      </button>
      <Snackbar
        open={openAlert.isOpen}
        autoHideDuration={TIME_OUT.ONE_SECOND}
        onClose={() => {
          setOpenAlert({ isOpen: false, message: "" })
        }}
      >
        <Alert severity="error">{t(`${openAlert.message}`)}</Alert>
      </Snackbar>
    </div>
  )
}

export default SuccessRegisterPage
