import { useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import ProtectedRoute from "./components/protected-route/ProtectedRoute"
import { clearStorage, getItemFromStorage } from "./common/storage"
import { OFFER_TYPE, STORAGE_KEY, TYPE_ACCOUNT } from "./constant/constant"

// INTERFACES
import { IRegister, IZipCodeCity } from "./interfaces/register-page.interface"

// CSS
import "./theme/typography.css"
import "./theme/utils.css"
import "./theme/component/button.css"
import "./theme/component/card.css"
import 'react-spring-bottom-sheet/dist/style.css'

// CONTEXT
import { initial, RegisterInfoContext } from "./context/register-context"
import { TypeAccountContext } from "./context/type-account.context"

// PAGES
import ConfirmEmailPage from "./pages/confirm-email-page/ConfirmEmailPage"
import AuthRoute from "./components/auth-route/AuthRoute"
import RegisterPage from "./pages/register-page/RegisterPage"
import SuccessRegisterPage from "./pages/success-register-page/SuccessRegisterPage"
import OtpPage from "./pages/otp-page/OtpPage"
import MainPage from "./pages/main-page/MainPage"
import Header from "./components/Header"
import { initialZipCodeCity, ZipCodeCityContext } from './context/zip-code-city-context';
import { TypeOfferContext } from './context/type-subscribtion.context';
import ClaimGiftPage from './pages/claim-gift-page/ClaimGiftPage';
import { SendVerifyCode } from "pages/send-verify-code/SendVerifyCode"
import { VerifyOtp } from "pages/verify-otp/VerifyOtp"
import ProtectedMerchantRoute from "components/protected-merchant-route/ProtectedMerchantRoute"
import { ClaimQrCode } from "pages/claim-QR-code/ClaimQrCode"
import { Toaster } from "react-hot-toast"
import { MerchantRoute } from "routes"
import { NotificationPage } from "pages/notification-page/NotificationPage"

const account = getItemFromStorage(STORAGE_KEY.TYPE_ACCOUNT)
const register = getItemFromStorage(STORAGE_KEY.REGISTER)


function App() {
  const [registerInfo, setRegisterInfo] = useState<IRegister>(
    register ? JSON.parse(register) : initial
  )
  const [zipCodeCityInfo, setZipCodeCityInfo] = useState<IZipCodeCity>(
    register ? JSON.parse(register) : initialZipCodeCity
  )
  const [accountType, setTypeAccount] = useState<string>(
    account ? account : OFFER_TYPE.STANDARD
  )

  const [typeOffer, setTypeOffer] = useState<string>(
    TYPE_ACCOUNT.product.value
  )


  useEffect(() => {
    const handleUnmount = () => {
      clearStorage()
    }
    window.addEventListener('beforeunload', handleUnmount);
    return () => {
      window.removeEventListener('beforeunload', handleUnmount);
    }
  }, [])

  return (
    <RegisterInfoContext.Provider value={{ registerInfo, setRegisterInfo }}>
      <Router>
        <Header />
        <Switch>
          <Route
            exact={true}
            path="/"
            children={
              <TypeOfferContext.Provider
                value={{ typeOffer: typeOffer, setTypeOffer }}
              >
                <TypeAccountContext.Provider
                  value={{ typeAccount: accountType, setTypeAccount }}
                >
                  <ZipCodeCityContext.Provider value={{ zipCodeCityInfo: zipCodeCityInfo, setZipCodeCityInfo }}>
                    <div>
                      <MainPage />
                    </div>
                  </ZipCodeCityContext.Provider>
                </TypeAccountContext.Provider>
              </TypeOfferContext.Provider>
            }
          />
          <Route
            path="/main/:type"
            children={
              <div className="App">
                <MainPage />
              </div>
            }
          />
          <Route
            path="/loyalty-gift"
            children={
              <div>
                <ClaimGiftPage />
              </div>
            }
          />
          <Route
            path="/confirm-email"
            children={
              <div>
                <ConfirmEmailPage />
              </div>
            }
          />
          <Route
            path="/success-register"
            children={
              <div>
                <SuccessRegisterPage />
              </div>
            }
          />
          <Route
            path="/send-verify-code"
            children={
              <div>
                <SendVerifyCode />
              </div>
            }
          />
          <ProtectedMerchantRoute
            path="/merchant-page"
            component={MerchantRoute}
          />
          <ProtectedMerchantRoute
            path="/merchant/dynamic-QR-code"
            component={ClaimQrCode}
          />
          <ProtectedMerchantRoute
            path="/merchant/notification"
            component={NotificationPage}
          />
          <Route
            path="/merchant/login"
            children={
              <div>
                <VerifyOtp />
              </div>
            }
          />
          <TypeAccountContext.Provider
            value={{ typeAccount: accountType, setTypeAccount }}
          >
            <AuthRoute path="/otp" component={OtpPage} />
            <ProtectedRoute path="/register" component={RegisterPage} />
          </TypeAccountContext.Provider>
          <Route
            path="*"
            children={<h2 className="text-center pt-20">404 NOT FOUND</h2>}
          />
        </Switch>
      </Router>
      <Toaster
        containerClassName=""
        containerStyle={{}}
        position="top-center"
      />
    </RegisterInfoContext.Provider>
  )
}

export default App
