import React, { useState } from "react";
import "./sendVerifyCode.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { sendVerifyCode } from "api/auth/place/auth-place.api";
import { useHistory } from "react-router-dom";
import { handleError } from "api/error/handle-error";
import toast from 'react-hot-toast';

export const SendVerifyCode = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [value, setValue] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setLoading(true);
    const countryCode = +value?.substring(0, 2);
    const phoneNumber = value?.substring(2);
    sendVerifyCode({
      countryCode,
      phoneNumber,
    }).then((res) => {
      if (res) {
        setLoading(false);
        history.push("/merchant/login", {
          codeId: res?.codeId,
          placeId: res?.placeId,
        });
      } else {
        setLoading(false);
        toast.error(t("noPhone"));
      }
    }).catch((error) => {
      handleError(error);
      setLoading(false);
      toast.error(t("noPhone"));
    })
  };
  return (
    <div className="verify-code-wrapper">
      <h4>{t("whatYourPhoneNumber")}?</h4>
      <div className="mt-20">
        <PhoneInput
          country={"fr"}
          countryCodeEditable={false}
          placeholder={t("enterPhoneNumber")}
          value={value}
          onChange={setValue}
          inputStyle={{ width: "100%" }}
        />
      </div>
      <div className="confirm-btn flex-center-center">
        <button
          disabled={isLoading}
          onClick={handleSubmit}
          className="btn-container btn btn-orange"
        >
          <span className="text-medium">{t("verifyPhoneNumber")}</span>
        </button>
      </div>
    </div>
  );
};
