import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";


export const MerchantSelectionHeader = ({
  onToggle,
}: {
  onToggle: (value: boolean) => void;
}) => {
  const useStyles = makeStyles((theme) => ({
    closeIcon: {
      position: "absolute",
      top: 18,
      right: 10,
    },
  }));
  const classes = useStyles();

  return (
    <div className="flex-center-center">
      <span className="text-medium text-500">Select zones</span>
      <CloseIcon
        onClick={() => onToggle(false)}
        className={classes.closeIcon}
      />
    </div>
  );
}