import App from "./App"
import reportWebVitals from "./reportWebVitals"
import ReactDOM from "react-dom"

// Redux
import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { Provider } from "react-redux"
import RootReducer from "./redux/reducers/RootReducer"
import { createLogger } from "redux-logger"

// Styles
import "./index.css"

// Language
import "./Languages/i18n"

const middleware = []
const reduxLogger = createLogger({
  duration: true,
  colors: {
    title: () => "red",
    prevState: () => "green",
    action: () => "blue",
    nextState: () => "orange",
  },
})
middleware.push(thunk)
if (!process.env.REACT_APP_STAGE) {
  middleware.push(reduxLogger)
}
const enhancer = compose(
  applyMiddleware(...middleware),
  //@ts-ignore
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  window.__REDUX_DEVTOOLS_EXTENSION__  ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  // use line 35 when deploy
)

const store = createStore(RootReducer, enhancer)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
