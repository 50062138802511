import {baseApi, getParams} from '../base/base.api'
import {handleError} from "../error/handle-error";
import { ICategoryResponse, IZone } from "../../interfaces/apis/category.inteface";
import {AxiosResponse} from "axios";

const PLACES_URL = {
    GET_CATEGORIES: '/v1/places/categories',
    GET_ZONES: '/v1/places/map-zones'
}

export const getCategories = (_params: any): Promise<void | AxiosResponse<ICategoryResponse>> => {
    const params = getParams(_params)
    return baseApi.get<ICategoryResponse>(PLACES_URL.GET_CATEGORIES, {params}).catch(handleError)
}

export const getZones = (): Promise<IZone[]> => {
    return baseApi.get(PLACES_URL.GET_ZONES);
};