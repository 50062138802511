import { baseApi } from '../base/base.api'
import { AxiosResponse } from "axios";
import { IOtp } from "../../interfaces/otp.interface";

const OTP_URL = {
    CREATE_OTP: '/v1/twilio/create',
    RESEND_OTP: '/v1/twilio/resend',
    VALIDATE_OTP: '/v1/twilio/validate',
}

export const createOtp = (): Promise<AxiosResponse<IOtp>> => {
    return baseApi.post<IOtp>(OTP_URL.CREATE_OTP)
}

export const resendOtp = (id: string): Promise<AxiosResponse<IOtp>> => {
    return baseApi.post<IOtp>(OTP_URL.RESEND_OTP, { id })
}

export const validateOtp = (body: { codeId: string, userInputCode: string }): Promise<AxiosResponse<IOtp> | void> => {
    return baseApi.post<IOtp>(OTP_URL.VALIDATE_OTP, body)
}
