import { clearStorage } from "../../common/storage";
import { ERROR, TIME_OUT } from "../../constant/constant";

export class ErrorState {
    private errorText: string = ''
    private static instance: ErrorState

    private constructor() {
    }

    public static getInstance(): ErrorState {
        if (!ErrorState.instance) {
            ErrorState.instance = new ErrorState()
        }

        return ErrorState.instance
    }

    public setErrorText(text: string) {
        this.errorText = text
    }

    public getErrorText(): string {
        return this.errorText
    }
}

export const handleError = (error: any, callback?: () => void) => {
    const errorState = ErrorState.getInstance()

    switch (error.response.data.statusCode) {
        case 62:
            clearStorage()
            errorState.setErrorText(ERROR.REGISTRATION_TIMEOUT)
            if (callback) setTimeout(() => callback(), TIME_OUT.THREE_SECOND)
            break
        case 110:
            // clearStorage()
            errorState.setErrorText(ERROR.REGISTRATION_TIMEOUT)
            if (callback) setTimeout(() => callback(), TIME_OUT.THREE_SECOND)
            break
        default:
            errorState.setErrorText(error.response.data.message || error.message)
            break
    }
}

