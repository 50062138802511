import { combineReducers } from "redux"

// Reducers
import MerchantReducer from "./MerchantReducer"

//Types
import { MerchantState } from "../../types/MerchantState"

export interface RootState {
  MerchantReducer: MerchantState
}

const RootReducer = combineReducers({
  MerchantReducer,
})

export default RootReducer
